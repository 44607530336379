import {  GingerMediaBaseUrl } from "./baseUrls";
export interface CreativeSpecProperty {
  name: string;
  value: string;
}

export interface AdditionalInstructionProperty {
  name: string;
  value: string;
}

export interface SopProperty {
  name: string;
  value: string;
}

export interface AdvertisingOptions {
  adOption: string;
  imageUrls: string[];
  description: string;
  tags:string;
  subDescription: string;
  minBilling: number|null;
  creativeSpec: {
    title: string;
    properties: CreativeSpecProperty[];
  };
  additionalInstruction: {
    properties: AdditionalInstructionProperty[];
  };
  sop: {
    properties: SopProperty[];
  };
  priceUnit: string;
  price: string;
  title: string;
  kiUsedFor: string;
  kiAdType: string;
  kiLeadTime: number|null;
  priceDescription: string;
  span?: number|null;
  adOptionType?: string;
  area?: string;
  minimumBilling?: number|null;
  AdOptionsTypeId?:number;
}
export interface MediaItem {
  gmgAssetCode: string;
  service: string;
  category: string;
  advertisingOptions:AdvertisingOptions[];
  subCategory?: string;
  location: string;
  area?: string;
  city?: string;
  price: number;
  imageUrl: string;
  latitude : string;
  longitude: string;
  faq: string;
  description: string;
  keyInsights: Array<{ key: string; value: string }>;
  title:string;
}

export interface MediaResponse {
  data: MediaItem[];
  nextOffset: number | null;
  totalCount: number;
}

export async function getAdvertisingMediaByService(
  service: string,
  offset: number,
  searchTerm?: string,
  filters?: Record<string, string>
): Promise<MediaResponse | undefined> {
  const params = new URLSearchParams({
    service: `${service}`,
    offset: `${offset}`,
    ...(searchTerm ? { search: searchTerm } : {}),
  });

  if (filters !== undefined) {
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== undefined) {
        console.log(`Adding filter: ${key}=${filters[key]}`);
        params.append(key, filters[key]);
      }
    });
  }
  const url = `${GingerMediaBaseUrl}/getAdvertisingMediaByService?${params.toString()}`;
  
  try {
    const response = await fetch(url, {
      method: "GET",
    });

    console.log({response})
    if (!response.ok) {
      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error(error);
  }
}

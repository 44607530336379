import { useState } from "react";
import createFileUpload, { Body } from "../clients/createFileUpload";
import generatePresignedUrls from "../clients/generatePresignedUrls";
import completeFileUpload, {
  UploadedPart,
} from "../clients/completeFileUpload";
import uploadByPart from "../clients/uploadByParts";
import { useAuthContext } from "../providers/AuthProvider";
import { uploadFileWithMetadata } from "../clients/fsUuploadFileWithMetadata";

// Set part size to 5 MB
const PART_SIZE = 5 * 1024 * 1024;

type UploadFileHook = {
  uploadFile: (index: number, file: File) => Promise<string | null>;
  loading: boolean;
  error: string | null;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  progress: Record<string, number>; // Store progress for each file
  setProgressCallback: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >;
};

const useFileUpload = (
  setProgressCallback: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >,
  deletingIndexRef: React.MutableRefObject<number | null>
): UploadFileHook => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState<Record<number, number>>({}); // Track progress for each file
  const { authHeader } = useAuthContext();

  const uploadFile = async (
    index: number,
    file: File,
    tags?: string,
    metadata?: string
  ): Promise<string | null> => {
    if (deletingIndexRef.current === index) {
      deletingIndexRef.current = null;
      // Stop API calls if the file is being deleted
      console.log(
        `Upload for index ${index} stopped because the file is being deleted.`
      );
      return null;
    }

    const fileName = file.name;
    const fileType = file.type;
    console.log({ fileName, fileType });
    console.log("File Type:", fileType);

    const allowedFileTypes = [
      "application/pdf", // PDF
      "application/vnd.ms-powerpoint", // PPT
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PPTX
      "application/vnd.ms-excel", // XLS
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
      "application/zip", // ZIP
      "application/x-zip-compressed", // ZIP (alternate MIME type)
    ];

    if (!allowedFileTypes.includes(fileType)) {
      setError("Invalid file type. Please upload a .pptx, .zip, or .pdf file.");
      return null;
    }

    try {
      if (!authHeader) return null;
      setLoading(true);
      const fileSize = file.size;
      const totalParts = Math.ceil(fileSize / PART_SIZE);

      setProgress((prev) => ({
        ...prev,
        [index]: 0,
      }));

      setProgressCallback((prev) => ({
        ...prev,
        [index]: 0,
      }));

      const updatedMetadata = metadata
        ? { ...JSON.parse(metadata), fileName, fileType, fileSize }
        : { fileName, fileType, fileSize };

      const payload: Body = {
        fileName: fileName,
        fileType,
        tags: tags + "AUDIT DATA",
        metadata: JSON.stringify(updatedMetadata),
      };

      // Handle small files with direct upload
      if (fileSize <= PART_SIZE) {
        setProgress((prev) => ({ ...prev, [index]: 0 }));
        setProgressCallback((prev) => ({ ...prev, [index]: 0 }));

        const uploadProgress = (progressPercentage: number) => {
          setProgress((prev) => ({ ...prev, [index]: progressPercentage }));
          setProgressCallback((prev) => ({
            ...prev,
            [index]: progressPercentage,
          }));
        };

        const formData = new FormData();
        formData.append(fileName, file);
        formData.append("tags", payload.tags);
        formData.append("metadata", payload.metadata);

        // Simulate progress
        uploadProgress(50); // Start with 50% progress
        const response = await uploadFileWithMetadata(authHeader, formData);
        uploadProgress(100); // Mark 100% progress
        setLoading(false);

        const responseData = await response.json();
        const imageUrl = responseData.fileData?.fileUrl;

        return imageUrl;
      }

      const response = await createFileUpload(authHeader, payload);
      const uploadId = (response.data as { UploadId: string }).UploadId;
      const fileId = (response.data as { fileId: string }).fileId;
      if (deletingIndexRef.current === index) {
        deletingIndexRef.current = null;
        // Stop API calls if the file is being deleted
        console.log(`Upload for index ${index} stopped during part uploads.`);
        return null;
      }
      const presignedUrls = await generatePresignedUrls(
        fileId,
        uploadId,
        totalParts
      );
      if (deletingIndexRef.current === index) {
        deletingIndexRef.current = null;
        // Stop API calls if the file is being deleted
        console.log(`Upload for index ${index} stopped during part uploads.`);
        return null;
      }
      const urls = (
        presignedUrls.data as {
          presignedUrls: { partNumber: number; url: string }[];
        }
      ).presignedUrls;

      const uploadedParts: UploadedPart[] = [];
      let uploadedCount = 0;

      for (let i = 0; i < totalParts; i++) {
        if (deletingIndexRef.current === index) {
          deletingIndexRef.current = null;
          // Stop API calls if the file is being deleted
          console.log(`Upload for index ${index} stopped during part uploads.`);
          return null;
        }

        const start = i * PART_SIZE;
        const end = Math.min(start + PART_SIZE, file.size);
        const chunk = file.slice(start, end);

        const etag = await uploadFilePart(urls[i].url, chunk);
        uploadedParts.push({
          ETag: etag,
          PartNumber: i + 1,
        });

        uploadedCount++;
        const progressPercentage = Math.round(
          (uploadedCount / totalParts) * 100
        );

        setProgress((prevProgress) => ({
          ...prevProgress,
          [index]: progressPercentage,
        }));

        setProgressCallback((prev) => ({
          ...prev,
          [index]: progressPercentage,
        }));
      }

      const fileResponse = await completeFileUpload(
        fileId,
        uploadId,
        uploadedParts
      );
      const location = (fileResponse.data as { location: string }).location;

      return location;
    } catch (uploadError) {
      setError("Error during file upload.");
      console.error(uploadError);
      setLoading(false);
      return null;
    }
  };

  // Function to upload individual file parts
  const uploadFilePart = async (
    url: string,
    partBuffer: Blob
  ): Promise<string> => {
    const response = await uploadByPart(url, partBuffer);
    return response.headers.get("etag") || "";
  };

  return {
    uploadFile,
    loading,
    setLoading,
    error,
    progress,
    setProgressCallback,
  };
};

export default useFileUpload;

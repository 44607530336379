import React, {  useState } from "react";
import {
  TextField,
  Button,
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  InputAdornment,
  Container,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useAuthContext } from "../../../providers/AuthProvider";
import { AdvertisingOptions } from "../../../clients/getAdvertisingMediaByService";
import deleteFileFromS3 from "../../../clients/deleteFileFromS3";
import { uploadFileWithMetadata } from "../../../clients/fsUuploadFileWithMetadata";
import { updateAdvertisingOptions } from "../../../clients/updateAdvertisingOptions";
interface EditAdvertisingOptionProps {
    option: AdvertisingOptions;
    gmgAssetCode:string;
  }
  const EditAdvertisingOption: React.FC<EditAdvertisingOptionProps> = ({ option,gmgAssetCode }) => {
    const [formData, setFormData] = useState<AdvertisingOptions>(option);
 
  const [loading, setLoading] = useState(false);
  const { authHeader } = useAuthContext();
  const [imageInput, setImageInput] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

 
  // Handle form submission for updating
  const handleSubmit = async () => {
    console.log(gmgAssetCode,option.adOption,option.AdOptionsTypeId)
    if (!gmgAssetCode || !option.adOption ||!option.AdOptionsTypeId) return;

    setLoading(true);
    try {
      const formattedData = {
        ...formData,
        gmgAssetCode: gmgAssetCode,
        imageUrls:
          formData.imageUrls.length === 1
            ? formData.imageUrls[0]
            : formData.imageUrls,
        creativeSpec: JSON.stringify({
          title: formData.creativeSpec.title,
          properties: formData.creativeSpec.properties,
        }),
        price:typeof(formData.price)!=="string"?Number(formData.price):Number(formData.price?.split(".")[0].replace(/\D/g, "")),
        minimumBilling:Number(formData.minimumBilling),
        additionalInstruction: JSON.stringify(formData.additionalInstruction),
        sop: JSON.stringify(formData.sop),
        adOptionType: formData.adOption,
      };
      await updateAdvertisingOptions(option.adOption,option.AdOptionsTypeId,gmgAssetCode,formattedData)
      
    } catch (error) {
      console.error("Error updating advertising option:", error);
      alert("Failed to update advertising option. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Reuse the rest of the functions and JSX from CreateAdvertisingOption
  const handleImageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageInput(e.target.value);
  };

  const handleImageSubmit = async () => {
    if (!authHeader) return;

    const urls = imageInput.split(",").map((url) => url.trim());
    setIsUploading(true);

    try {
      for (const url of urls) {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], "image.png", { type: blob.type });

        const formData = new FormData();
        formData.append("image", file);

        const metadata = {
          fileName: file.name,
          uploaderName: "",
        };

        formData.append("tags", "MEDIA IMAGE");
        formData.append("metadata", JSON.stringify(metadata));
        const res = await uploadFileWithMetadata(authHeader, formData);
        const responseData = await res.json();
        const imageUrl = responseData.fileData?.fileUrl;
        if (imageUrl) {
          setFormData((prev) => ({
            ...prev,
            imageUrls: [...prev.imageUrls, imageUrl],
          }));
        }
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      alert("Failed to upload images. Please try again.");
    } finally {
      setIsUploading(false);
      setImageInput("");
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && authHeader) {
      setIsUploading(true);
      try {
        const formData = new FormData();
        formData.append("image", file);

        const metadata = {
          fileName: file.name,
          uploaderName: "",
        };

        formData.append("tags", "MEDIA IMAGE");
        formData.append("metadata", JSON.stringify(metadata));

        const res = await uploadFileWithMetadata(authHeader, formData);
        const responseData = await res.json();
        const imageUrl = responseData.fileData?.fileUrl;

        if (imageUrl) {
          setFormData((prev) => ({
            ...prev,
            imageUrls: [...prev.imageUrls, imageUrl],
          }));
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("Failed to upload image. Please try again.");
      } finally {
        setIsUploading(false);
        event.target.value = "";
      }
    }
  };

  const handleImageDelete = async (imageUrl: string) => {
    if (!authHeader) return;

    setIsDeleting(true);
    try {
      await deleteFileFromS3(authHeader, imageUrl);
      setFormData((prev) => ({
        ...prev,
        imageUrls: prev.imageUrls.filter((url) => url !== imageUrl),
      }));
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Failed to delete image. Please try again.");
    } finally {
      setIsDeleting(false);
    }
  };

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      if (name === "price") {
        const numericValue = value.split(".")[0].replace(/\D/g, ""); 
  
        setFormData({ 
          ...formData, 
          [name]: numericValue 
        });
      } else {
        setFormData({ ...formData, [name]: value });
      }
   
    };

  const handleCreativeSpecChange = (index: number, field: string, value: string) => {
    const updatedProperties = [...formData.creativeSpec.properties];
    updatedProperties[index] = { ...updatedProperties[index], [field]: value };
    setFormData({
      ...formData,
      creativeSpec: { ...formData.creativeSpec, properties: updatedProperties },
    });
  };

  const handleAdditionalInstructionChange = (index: number, field: string, value: string) => {
    const updatedProperties = [...formData.additionalInstruction.properties];
    updatedProperties[index] = { ...updatedProperties[index], [field]: value };
    setFormData({
      ...formData,
      additionalInstruction: {
        ...formData.additionalInstruction,
        properties: updatedProperties,
      },
    });
  };

  const handleSopChange = (index: number, field: string, value: string) => {
    const updatedProperties = [...formData.sop.properties];
    updatedProperties[index] = { ...updatedProperties[index], [field]: value };
    setFormData({
      ...formData,
      sop: { ...formData.sop, properties: updatedProperties },
    });
  };

  const addProperty = (field: "creativeSpec" | "additionalInstruction" | "sop") => {
    setFormData({
      ...formData,
      [field]: {
        ...formData[field],
        properties: [...formData[field].properties, { name: "", value: "" }],
      },
    });
  };

  const deleteProperty = (field: "creativeSpec" | "additionalInstruction" | "sop", index: number) => {
    const updatedProperties = formData[field].properties.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      [field]: {
        ...formData[field],
        properties: updatedProperties,
      },
    });
  };

  return (
    <Container sx={{ mt: 2 }}>
      <Box component="form" autoComplete="off" sx={{}}>
        <TextField
          name="adOption"
          label="Ad Option"
          value={formData.adOption}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        {/* Display Uploaded Images with Delete Option */}
        {formData.imageUrls.length > 0 && (
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Uploaded Images
            </Typography>
            <Grid container spacing={2}>
              {formData.imageUrls
                .filter((url) => url.trim() !== "")
                .map((url, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <Box position="relative">
                      <img
                        src={url}
                        alt={`Uploaded ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: 8,
                        }}
                      />
                      <IconButton
                        onClick={() => handleImageDelete(url)}
                        disabled={isDeleting}
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
        )}
        <Typography variant="h6" gutterBottom>
          Image URLs
        </Typography>
        <TextField
          label="Image URLs"
          value={imageInput}
          onChange={handleImageInputChange}
          fullWidth
          margin="normal"
          helperText="Separate multiple URLs with a comma"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  onClick={handleImageSubmit}
                  disabled={isUploading}
                >
                  {isUploading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
              </InputAdornment>
            ),
          }}
        />
        <Box mt={2}>
          <Button
            variant="outlined"
            component="label"
            startIcon={<PhotoCameraIcon />}
          >
            Upload Image
            <input
              type="file"
              hidden
              onChange={handleImageUpload}
              accept="image/*"
            />
          </Button>
          {isUploading && <CircularProgress size={24} sx={{ ml: 2 }} />}
        </Box>
        <TextField
          name="description"
          label="Description"
          value={formData.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="subDescription"
          label="Sub Description"
          value={formData.subDescription}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="minimumBilling"
          label="Minimum Billing"
          type="number"
          value={formData.minimumBilling}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Typography variant="h6" gutterBottom>
          Creative Spec
        </Typography>
        <TextField
          name="creativeSpecTitle"
          label="Creative Spec Title"
          value={formData.creativeSpec.title}
          onChange={(e) =>
            setFormData({
              ...formData,
              creativeSpec: {
                ...formData.creativeSpec,
                title: e.target.value, // Update the title field
              },
            })
          }
          fullWidth
          margin="normal"
        />
        {formData?.creativeSpec?.properties?.map((property, index) => (
          <Grid container spacing={2} key={index} alignItems="center">
            <Grid item xs={5}>
              <TextField
                label="Property Name"
                value={property.name}
                onChange={(e) =>
                  handleCreativeSpecChange(index, "name", e.target.value)
                }
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Property Value"
                value={property.value}
                onChange={(e) =>
                  handleCreativeSpecChange(index, "value", e.target.value)
                }
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => deleteProperty("creativeSpec", index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => addProperty("creativeSpec")}
          sx={{ mt: 2 }}
        >
          Add Property
        </Button>
        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Additional Instruction
        </Typography>
        {formData?.additionalInstruction?.properties?.map((property, index) => (
          <Grid container spacing={2} key={index} alignItems="center">
            <Grid item xs={5}>
              <TextField
                label="Property Name"
                value={property.name}
                onChange={(e) =>
                  handleAdditionalInstructionChange(
                    index,
                    "name",
                    e.target.value
                  )
                }
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Property Value"
                value={property.value}
                onChange={(e) =>
                  handleAdditionalInstructionChange(
                    index,
                    "value",
                    e.target.value
                  )
                }
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={() => deleteProperty("additionalInstruction", index)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => addProperty("additionalInstruction")}
          sx={{ mt: 2 }}
        >
          Add Property
        </Button>
        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          SOP
        </Typography>
        {formData?.sop?.properties?.map((property, index) => (
          <Grid container spacing={2} key={index} alignItems="center">
            <Grid item xs={5}>
              <TextField
                label="Property Name"
                value={property.name}
                onChange={(e) => handleSopChange(index, "name", e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Property Value"
                value={property.value}
                onChange={(e) =>
                  handleSopChange(index, "value", e.target.value)
                }
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => deleteProperty("sop", index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => addProperty("sop")}
          sx={{ mt: 2 }}
        >
          Add Property
        </Button>
        <TextField
          name="tags"
          label="Tags"
          value={formData.tags}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="priceUnit"
          label="Price Unit"
          value={formData.priceUnit}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="price"
          label="Price"
          value={formData.price}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="title"
          label="Title"
          value={formData.title}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="kiUsedFor"
          label="KI Used For"
          value={formData.kiUsedFor}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="kiAdType"
          label="KI Ad Type"
          value={formData.kiAdType}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="kiLeadTime"
          label="KI Lead Time"
          value={formData.kiLeadTime}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="priceDescription"
          label="Price Description"
          value={formData.priceDescription}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="area"
          label="Area"
          value={formData.area}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="span"
          label="Span"
          type="number"
          value={formData.span}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 4 }}
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit"}
        </Button>
      </Box>
    </Container>
  );
};

export default EditAdvertisingOption;
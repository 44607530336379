import React from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import AdvertisingOptionCard from './AdvertisingOptionCard';
import { AdvertisingOptions } from '../../../clients/getAdvertisingMediaByService';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

interface AdvertisingOptionsProps {
  advertisingOptions: AdvertisingOptions[];
  gmgAssetCode: string | undefined;
}

const AdvertisingOptionsList: React.FC<AdvertisingOptionsProps> = ({
  advertisingOptions,
    gmgAssetCode
}) => {
const navigate = useNavigate();
  return (
    <div>
      {/* {showCreateForm && <CreateAdvertisingOption />} */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
        <Card sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
      <CardContent>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => navigate(`/createAdvertisingOption/${gmgAssetCode}`)} 
          sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
        >
          <Typography variant="h6">Create Advertising Option</Typography>
        </Button>
      </CardContent>
    </Card>
        </Grid>
        {advertisingOptions.map((option, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
           {gmgAssetCode && (<AdvertisingOptionCard option={option} gmgAssetCode={gmgAssetCode} />)} 
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default AdvertisingOptionsList;
import JSZip from "jszip";
import Papa from "papaparse";
import * as XLSX from "xlsx";

export const columnMapper = (columns: string[]): string[] => {
  const mappings: { [key: string]: string } = {
    State: "state",
    City: "city",
    Type: "type",
    Pincode: "pincode",
    Area: "area",
    Location: "location",
    Towards: "towards",
    Width: "width",
    Height: "height",
    "Total Sqft": "areaInSqFeet",
    "Display Cost per Month": "displayCostPerMonth",
    Longitude: "longitude",
    Latitude: "latitude",
    Landmark: "landmark",
    "Vendor Id": "vendorId",
    "Vendor ID": "vendorId",
    "Vendor Asset Code": "vendorAssetCode",
    "Vendor Name": "vendorName",
    Remarks: "remarks",
    "Mounting Price": "mountingPrice",
    "Printing Charge": "printingCharge",
  };

  return columns.map((column) => mappings[column] || column);
};

export const extractImagesFromPptx = async (file: File): Promise<File[]> => {
  const zip = new JSZip();
  const pptxData = await zip.loadAsync(file);
  const images: File[] = [];
  const imageSet = new Set<string>(); // To track unique images

  // A utility function to determine MIME type based on file extension
  const getMimeType = (fileName: string): string => {
    const extension = fileName.split(".").pop()?.toLowerCase();
    switch (extension) {
      case "jpeg":
      case "jpg":
        return "image/jpeg";
      case "png":
        return "image/png";
      case "gif":
        return "image/gif";
      case "bmp":
        return "image/bmp";
      case "tiff":
        return "image/tiff";
      case "svg":
        return "image/svg+xml";
      default:
        return "application/octet-stream";
    }
  };

  // Get the slide relationships to extract images in the correct order
  const slidePaths = Object.keys(pptxData.files).filter(
    (path) => path.startsWith("ppt/slides/slide") && path.endsWith(".xml")
  );
  slidePaths.sort((a, b) => {
    const getSlideNumber = (path: string) =>
      parseInt(path.match(/slide(\d+)\.xml/)?.[1] || "0", 10);
    return getSlideNumber(a) - getSlideNumber(b);
  });

  for (const slidePath of slidePaths) {
    const slideContent = await pptxData.files[slidePath].async("string");
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(slideContent, "application/xml");

    // Find image references in the slide, excluding background images
    const imageNodes = xmlDoc.getElementsByTagName("a:blip");
    const imageNodesArray = Array.from(imageNodes);

    for (const imageNode of imageNodesArray) {
      const embedAttr = imageNode.getAttribute("r:embed");
      if (embedAttr) {
        const relsPath =
          slidePath.replace("slides/slide", "slides/_rels/slide") + ".rels";
        const relsContent = await pptxData.files[relsPath].async("string");
        const relsXml = parser.parseFromString(relsContent, "application/xml");
        const targetAttr = Array.from(
          relsXml.getElementsByTagName("Relationship")
        )
          .find((rel) => rel.getAttribute("Id") === embedAttr)
          ?.getAttribute("Target");

        if (targetAttr) {
          const imagePath = "ppt/" + targetAttr.replace("../", "");
          if (pptxData.files[imagePath] && !imageSet.has(imagePath)) {
            imageSet.add(imagePath);
            const arrayBuffer = await pptxData.files[imagePath].async(
              "arraybuffer"
            );
            const mimeType = getMimeType(imagePath);

            const imageBlob = new Blob([arrayBuffer], { type: mimeType });
            const imageObjectUrl = URL.createObjectURL(imageBlob);
            const img = new Image();
            img.src = imageObjectUrl;
            await new Promise((resolve) => {
              img.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                const context = canvas.getContext("2d");
                if (context) {
                  context.drawImage(img, 0, 0, img.width, img.height);
                  const imageData = context.getImageData(
                    0,
                    0,
                    img.width,
                    img.height
                  );

                  let totalBrightness = 0;
                  const pixelCount = imageData.data.length / 4;

                  for (let i = 0; i < imageData.data.length; i += 4) {
                    const r = imageData.data[i];
                    const g = imageData.data[i + 1];
                    const b = imageData.data[i + 2];
                    const brightness = (r + g + b) / 3;
                    totalBrightness += brightness;
                  }

                  const averageBrightness = totalBrightness / pixelCount;
                  const isBlank = averageBrightness > 240;

                  if (!isBlank) {
                    const imageFile = new File([arrayBuffer], imagePath, {
                      type: mimeType,
                    });
                    images.push(imageFile);
                  } else {
                    console.log(
                      `Skipped blank image: ${imagePath} from slide: ${slidePath}`
                    );
                  }
                }
                URL.revokeObjectURL(imageObjectUrl);
                resolve(null);
              };
            });
          }
        }
      }
    }
  }
  console.log(`Total unique images extracted: ${images.length}`);
  return images;
};

const removeEmptyRows = (rows: string[][]) => {
  return rows.filter((row) => row.some((cell) => cell !== null && cell !== ""));
};

const parseCSV = (
  data: string
): { columns: string[]; filteredData: string[][] } => {
  const parsedCSV = Papa.parse<string[]>(data, {
    header: false,
  });
  const columns = parsedCSV.data[0] as string[];
  const filteredData = removeEmptyRows(parsedCSV.data);
  return { columns, filteredData };
};

const parseExcel = (
  data: string
): { columns: string[]; filteredData: string[][] } => {
  const workbook = XLSX.read(data, { type: "binary" });
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];
  const parsedExcel = XLSX.utils.sheet_to_json<string[]>(sheet, {
    header: 1,
    defval: null,
  });
  const columns = parsedExcel[0] as string[];
  const filteredData = removeEmptyRows(parsedExcel);
  return { columns, filteredData };
};

export const fileParser = async (
  uploadedFile: File
): Promise<{ columns: string[]; filteredData: string[][] }> => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      if (e.target) {
        const data = e.target.result as string;
        const parsedFile: { columns: string[]; filteredData: string[][] } =
          uploadedFile.type === "text/csv" ? parseCSV(data) : parseExcel(data);
        resolve(parsedFile);
      }
    };

    reader.onerror = () => {
      reject(new Error("Failed to read the file"));
    };

    if (uploadedFile.type === "text/csv") {
      reader.readAsText(uploadedFile);
    } else {
      reader.readAsBinaryString(uploadedFile);
    }
  });
};

export const handleDownloadSample = (service: string) => {
  if (service === "") return;
  const wb = XLSX.utils.book_new();
  const ws_data = [requiredHeadersByMedium[service as keyof typeof requiredHeadersByMedium]];
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  XLSX.utils.book_append_sheet(wb, ws, "Sample");
  XLSX.writeFile(wb, `${service}_sample_template.xlsx`);
};

export const requiredHeadersByMedium: { [key: string]: string[] } = {
  outdoor: [
    "state",
    "city",
    "type",
    "pincode",
    "area",
    "location",
    "towards",
    "width",
    "height",
    "areaInSqFeet",
    "displayCostPerMonth",
    "longitude",
    "latitude",
    "landmark",
    "vendorId",
    "vendorAssetCode",
    "vendorName",
    "remarks",
  ],
  digital: [
    "mediaType",
    "zone",
    "locality",
    "propertyName",
    "pinCode",
    "propertyPriceUpto",
    "screenSize",
    "noOfTowers",
    "noOfScreens",
    "captiveHouseholds",
    "approxReach",
    "noOfImpressionsPerMonth",
    "locationMapLink",
    "latitude",
    "longitude",
    "facilityType",
    "tenantDetails",
    "nccsClass",
    "mediaSiteId",
  ],
  airport: [
    "subCategory",
    "location",
    "area",
    "city",
    "state",
    "price",
    "description",
    "latitude",
    "longitude",
    "monthlyPassengers",
    "domesticTravelers",
    "internationalTravelers",
    "numOfTerminals",
    "airportType",
  ],
  airline: [
    "subCategory",
    "location",
    "area",
    "city",
    "state",
    "price",
    "description",
    "latitude",
    "longitude",
    "totalNoAirlines",
    "avgSeatInFlight",
    "occupancyRate",
    "avgJourneyTime",
    "departurePerDay",
    "marketShare",
  ],
};


export const handleDownloadSample1 = (service: string, category: string) => {
  // Validate inputs
  if (!service || !category) {
    console.error("Both service and category are required.");
    return;
  }

  // Check if the service exists in the mapper
  const serviceMapper = subCategoryKeyInsightMapper[service];
  if (!serviceMapper) {
    console.error(`No mapping found for the service: ${service}`);
    return;
  }

  // Check if the category exists in the service mapper
  const categoryMapper = serviceMapper[category];
  if (!categoryMapper) {
    console.error(`No mapping found for the category: ${category} in service: ${service}`);
    return;
  }

  // Gather all headers from the mapper for the given service and category
  let headers: string[] = [];
  for (const subCategory in categoryMapper) {
    headers = [...Array.from(new Set([...headers, ...categoryMapper[subCategory]]))];
  }

  // Create a workbook and worksheet
  const workbook = XLSX.utils.book_new();
  const worksheetData = [headers]; // Add headers as worksheet data
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Append worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sample");

  // Write the workbook to a file
  const fileName = `${service}_${category}_sample_template.xlsx`;
  XLSX.writeFile(workbook, fileName);

  console.log(
    `Sample template for service: ${service}, category: ${category} downloaded successfully as ${fileName}`
  );
};

const COMMON_FIELDS = [
  "subCategory",
  "location",
  "area",
  "city",
  "state",
  "price",
  "description",
  "latitude",
  "longitude",
];

export const subCategoryKeyInsightMapper: Record<
  string,
  Record<string, Record<string, string[]>>
> = {
  // Non Traditional
  nonTraditional: {
    transit: {
      acBus: [
        ...COMMON_FIELDS,
        "estimatedFleet",
        "dailyRidershipCount",
        "operatorTypes",
        "operatorName",
        "totalRoutesLength",
        "avgDistancePerDay",
      ],
      auto: [...COMMON_FIELDS, "avgDistancePerDay"],
      bicycleShelters: [...COMMON_FIELDS],
      busStand: [...COMMON_FIELDS],
      cab: [...COMMON_FIELDS, "avgDistancePerDay", "estimatedFleet"],
      electricVehicle: [...COMMON_FIELDS, "estimatedFleet", "avgDistancePerDay"],
      expressTrain: [...COMMON_FIELDS, "stationsCovered", "dailyRidershipCount"],
      localTrain: [...COMMON_FIELDS, "dailyRidershipCount"],
      metroTrain: [
        ...COMMON_FIELDS,
        "dailyRidershipCount",
        "stationsCovered",
        "totalRoutesLength",
      ],
      metroStation: [...COMMON_FIELDS, "expectedReach"],
      mobileVan: [...COMMON_FIELDS, "avgDistancePerDay", "totalOperationalHours"],
      nonAcBus: [
        ...COMMON_FIELDS,
        "estimatedFleet",
        "dailyRidershipCount",
        "operatorTypes",
        "operatorName",
        "travelType",
        "totalRoutesLength",
        "avgDistancePerDay",
      ],
      others: [...COMMON_FIELDS],
      petrolPump: [...COMMON_FIELDS],
      railwayStation: [
        ...COMMON_FIELDS,
        "totalReach",
        "avgFootFall",
        "expectedReach",
        "travelType",
      ],
    },
    workAndEducation: {
      colleges: [...COMMON_FIELDS, "expectedReach", "totalReach"],
      itPark: [
        ...COMMON_FIELDS,
        "numOfEmployees",
        "numOfCompanies",
        "totalOperationalHours",
      ],
      notebooks: [...COMMON_FIELDS],
      others: [...COMMON_FIELDS],
      school: [...COMMON_FIELDS, "expectedReach"],
      vendingMachine: [...COMMON_FIELDS, "expectedReach"],
    },
    residential: {
      apartments: [
        ...COMMON_FIELDS,
        "noOfImpressions",
        "approxReach",
        "noOfTowers",
        "noOfFlats",
        "screenNo",
        "screenSize",
        "ncssClass",
        "propertyPrice",
      ],
      electricityBills: [...COMMON_FIELDS, "expectedReach"],
      gasBills: [...COMMON_FIELDS, "expectedReach"],
      others: [...COMMON_FIELDS],
      newspaperInsets: [...COMMON_FIELDS, "totalReach"],
      payingGuest: [...COMMON_FIELDS, "expectedReach"],
      noParkingBoard: [...COMMON_FIELDS],
      default: [...COMMON_FIELDS, "totalReach"],
    },
    healthAndFitness: {
      clinic: [...COMMON_FIELDS, "totalReach", "expectedReach", "screenNo"],
      gyms: [...COMMON_FIELDS, "totalReach"],
      others: [...COMMON_FIELDS, "expectedReach"],
    },
    foodBeverageAndHospitality: {
      cafeCoffeeDay: [
        ...COMMON_FIELDS,
        "totalReach",
        "expectedReach",
        "totalOperationalHours",
        "approxReach",
        "screenNo",
      ],
      hotel: [...COMMON_FIELDS, "totalReach"],
      others: [...COMMON_FIELDS, "totalReach"],
      restaurantCafeAndBar: [...COMMON_FIELDS, "totalReach"],
      default: [...COMMON_FIELDS, "totalReach"],
    },
    retailAndMall: {
      mall: [...COMMON_FIELDS, "expectedReach", "avgFootFall"],
      relianceStores: [...COMMON_FIELDS, "expectedReach"],
      default: [...COMMON_FIELDS, "expectedReach"],
    },
  },

  // Airport-Airline
  airport: {
    airport: {
      airport: [
        ...COMMON_FIELDS,
        "monthlyPassengers",
        "domesticTravelers",
        "internationalTravelers",
        "numOfTerminals",
        "airportType",
      ],
      airline: [
        ...COMMON_FIELDS,
        "monthlyPassengers",
        "totalNoAirlines",
        "avgSeatInFlight",
        "occupancyRate",
        "avgJourneyTime",
        "departurePerDay",
        "marketShare",
      ],
    },
  },

  // InfluencerMarketing
  influencerMarketing: {
    default: {
      default: [
        ...COMMON_FIELDS,
        "numOfFollowers",
        "platform",
        "type",
        "gender",
        "avgLikes",
        "engagementRate",
        "avgReach",
        "avgViews",
        "avgComments",
      ],
    },
  },

  // Magazine branding
  magazine: {
    news: {
      default: [
        ...COMMON_FIELDS,
        "circulation",
        "frequency",
        "language",
        "coverPrice",
        "readership",
      ],
    },
    default: {
      default: [
        ...COMMON_FIELDS,
        "circulation",
        "frequency",
        "language",
        "coverPrice",
        "readership",
      ],
    },
  },

  // Radio
  radio: {
    radio: {
      default: [
        ...COMMON_FIELDS,
        "weeklyListnership",
        "cityRank",
        "frequency",
        "language",
        "primeTime",
      ],
    },
    default: {
      default: [
        ...COMMON_FIELDS,
        "weeklyListnership",
        "cityRank",
        "frequency",
        "language",
        "primeTime",
      ],
    },
  },

  // Television Branding
  television: {
    default: {
      default: [
        ...COMMON_FIELDS,
        "monthlyCumeReach",
        "language",
        "primeTime",
        "metroShare",
        "MonthlyAMA",
      ],
    },
  },

  // Cinema
  cinema: {
    cinema: {
      default: [
        ...COMMON_FIELDS,
        "numOfSeats",
        "screenCategory",
        "screenNo",
        "type",
        "cinemaChain",
        "mallNames",
        "screenCode",
      ],
    },
    video: {
      default: [
        ...COMMON_FIELDS,
        "numOfSeats",
        "screenCategory",
        "screenNo",
        "type",
        "cinemaChain",
        "mallNames",
        "screenCode",
      ],
    },
    default: {
      default: [
        ...COMMON_FIELDS,
        "numOfSeats",
        "screenCategory",
        "screenNo",
        "type",
        "cinemaChain",
        "mallNames",
        "screenCode",
      ],
    },
  },

  // Digital
  digital: {
    business: {
      default: [
        ...COMMON_FIELDS,
        "monthlyUniqueUsers",
        "medium",
        "pagePerVist",
        "pricingModel",
        "bidType",
        "appStoreRating",
        "language",
      ],
    },
    default: {
      default: [
        ...COMMON_FIELDS,
        "monthlyUniqueUsers",
        "medium",
        "pagePerVist",
        "pricingModel",
        "bidType",
        "appStoreRating",
        "language",
      ],
    },
  },

  // Outdoor
  outdoor: {
    hoarding: {
      default: [...COMMON_FIELDS, "lighting", "landmark", "quantity"],
    },
    default: {
      default: [...COMMON_FIELDS, "lighting", "landmark", "quantity"],
    },
  },

  // News Paper
  newsPaper: {
    asa: {
      default: [
        ...COMMON_FIELDS,
        "circulation",
        "printDay",
        "newspaperType",
        "language",
        "readership",
        "areaCovered",
      ],
    },
    default: {
      default: [
        ...COMMON_FIELDS,
        "circulation",
        "printDay",
        "newspaperType",
        "language",
        "readership",
        "areaCovered",
      ],
    },
  },

  sports: {
    default: {
      default: [...COMMON_FIELDS, "duration", "gender"],
    },
  },

  mall: {
    default: {
      default: [...COMMON_FIELDS, "duration", "gender"],
    },
  },
};

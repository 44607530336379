import { fetchWrapper } from "@gogig-in/gogig-clients";
import { GingerMediaApiStorageBaseUrl, clientId } from "./baseUrls";

export interface Body {
  fileName: string;
  fileType: string;
  tags: string;
  metadata: string;
}

export default function createFileUpload(authHeader: string, fileUrls: Body) {
  const body: Body = {
    fileName: fileUrls.fileName,
    fileType: fileUrls.fileType,
    tags: fileUrls.tags,
    metadata: fileUrls.metadata,
  };

  return fetchWrapper(
    `${GingerMediaApiStorageBaseUrl}/createFileUpload?clientId=${clientId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      body,
    }
  );
}

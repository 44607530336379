export const subCategoryKeyInsightMapper: Record<
  string,
  Record<string, Record<string, string[]>>
> = {
  // Non Traditional
  nonTraditional: {
    transit: {
      acBus: [
        "estimatedFleet",
        "dailyRidershipCount",
        "operatorTypes",
        "operatorName",
        "totalRoutesLength",
        "avgDistancePerDay",
      ],
      auto: ["avgDistancePerDay"],
      bicycleShelters: [""],
      busStand: [""],
      cab: ["avgDistancePerDay", "estimatedFleet"],
      electricVehicle: ["estimatedFleet", "avgDistancePerDay"],
      expressTrain: ["stationsCovered", "dailyRidershipCount"],
      localTrain: ["dailyRidershipCount"],
      metroTrain: [
        "dailyRidershipCount",
        "stationsCovered",
        "totalRoutesLength",
      ],
      metroStation: ["expectedReach"],
      mobileVan: ["avgDistancePerDay", "totalOperationalHours"],
      nonAcBus: [
        "estimatedFleet",
        "dailyRidershipCount",
        "operatorTypes",
        "operatorName",
        "travelType",
        "totalRoutesLength",
        "avgDistancePerDay",
      ],
      others: [],
      petrolPump: [],
      railwayStation: [
        "totalReach",
        "avgFootFall",
        "expectedReach",
        "travelType",
      ],
    },
    workAndEducation: {
      colleges: ["expectedReach", "totalReach"],
      itPark: ["numOfEmployees", "numOfCompanies", "totalOperationalHours"],
      notebooks: [],
      others: [],
      school: ["expectedReach"],
      vendingMachine: ["expectedReach"],
    },
    residential: {
      apartments: [
        "noOfImpressions",
        "approxReach",
        "noOfTowers",
        "noOfFlats",
        "screenNo",
        "screenSize",
        "ncssClass",
        "propertyPrice",
      ],
      electricityBills: ["expectedReach"],
      gasBills: ["expectedReach"],
      others: [],
      newspaperInsets: ["totalReach"],
      payingGuest: ["expectedReach"],
      noParkingBoard: [],
      default: ["totalReach"],
    },
    healthAndFitness: {
      clinic: ["totalReach", "expectedReach", "screenNo"],
      gyms: ["totalReach"],
      others: ["expectedReach"],
    },
    foodBeverageAndHospitality: {
      cafeCoffeeDay: [
        "totalReach",
        "expectedReach",
        "totalOperationalHours",
        "approxReach",
        "screenNo",
      ],
      hotel: ["totalReach"],
      others: ["totalReach"],
      restaurantCafeAndBar: ["totalReach"],
      default: ["totalReach"],
    },
    retailAndMall: {
      mall: ["expectedReach", "avgFootFall"],
      relianceStores: ["expectedReach"],
      default: ["expectedReach"],
    },
  },

  //Airport-Airline
  airport: {
    airport: {
      airport: [
        "monthlyPassengers",
        "domesticTravelers",
        "internationalTravelers",
        "numOfTerminals",
        "airportType",
      ],
      airline: [
        "monthlyPassengers",
        "totalNoAirlines",
        "avgSeatInFlight",
        "occupancyRate",
        "avgJourneyTime",
        "departurePerDay",
        "marketShare",
      ],
    },
  },

  //InfluencerMarketing
  influencerMarketing: {
    default: {
      default: [
        "numOfFollowers",
        "platform",
        "type",
        "gender",
        "avgLikes",
        "engagementRate",
        "avgReach",
        "avgViews",
        "avgComments",
      ],
    },
  },

  //Magazine branding
  magazine: {
    news: {
      default: [
        "circulation",
        "frequency",
        "language",
        "coverPrice",
        "readership",
      ],
    },
    default: {
      default: [
        "circulation",
        "frequency",
        "language",
        "coverPrice",
        "readership",
      ],
    },
  },

  //Radio
  radio: {
    radio: {
      default: [
        "weeklyListnership",
        "cityRank",
        "frequency",
        "language",
        "primeTime",
      ],
    },
    default: {
      default: [
        "weeklyListnership",
        "cityRank",
        "frequency",
        "language",
        "primeTime",
      ],
    },
  },

  // Television Branding
  television: {
    default: {
      default: [
        "monthlyCumeReach",
        "language",
        "primeTime",
        "metroShare",
        "MonthlyAMA",
      ],
    },
  },

  // Cinema
  cinema: {
    cinema: {
      default: [
        "numOfSeats",
        "screenCategory",
        "screenNo",
        "type",
        "cinemaChain",
        "mallNames",
        "screenCode",
      ],
    },
    video: {
      default: [
        "numOfSeats",
        "screenCategory",
        "screenNo",
        "type",
        "cinemaChain",
        "mallNames",
        "screenCode",
      ],
    },
    default: {
      default: [
        "numOfSeats",
        "screenCategory",
        "screenNo",
        "type",
        "cinemaChain",
        "mallNames",
        "screenCode",
      ],
    },
  },

  // Digital
  digital: {
    business: {
      default: [
        "monthlyUniqueUsers",
        "medium",
        "pagePerVist",
        "pricingModel",
        "bidType",
        "appStoreRating",
        "language",
      ],
    },
    default: {
      default: [
        "monthlyUniqueUsers",
        "medium",
        "pagePerVist",
        "pricingModel",
        "bidType",
        "appStoreRating",
        "language",
      ],
    },
  },

  //Outdoor
  outdoor: {
    hoarding: {
      default: ["lighting", "landmark", "quantity"],
    },
    default: {
      default: ["lighting", "landmark", "quantity"],
    },
  },

  //News paper
  newsPaper: {
    asa: {
      default: [
        "circulation",
        "printDay",
        "newspaperType",
        "language",
        "readership",
        "areaCovered",
      ],
    },
    default: {
      default: [
        "circulation",
        "printDay",
        "newspaperType",
        "language",
        "readership",
        "areaCovered",
      ],
    },
  },

  sports: {
    default: {
      default: ["duration", "gender"],
    },
  },

  mall: {
    default: {
      default: ["duration", "gender"],
    },
  },
};
export const commonFields = [
  { fieldName: "location", type: "text" },
  { fieldName: "area", type: "text" },
  { fieldName: "city", type: "text" },
  { fieldName: "state", type: "text" },
  { fieldName: "price", type: "number" },
  { fieldName: "description", type: "textarea" },
  { fieldName: "latitude", type: "number" },
  { fieldName: "longitude", type: "number" },
];

export const subCategoryKeyInsightMapperWithType: Record<
  string,
  Record<string, Record<string, { fieldName: string; type: string }[]>>
> = {
  // Non Traditional
  nonTraditional: {
    transit: {
      acBus: [
        { fieldName: "estimatedFleet", type: "number" },
        { fieldName: "dailyRidershipCount", type: "number" },
        { fieldName: "operatorTypes", type: "string" },
        { fieldName: "operatorName", type: "string" },
        { fieldName: "totalRoutesLength", type: "number" },
        { fieldName: "avgDistancePerDay", type: "number" },
      ],
      auto: [{ fieldName: "avgDistancePerDay", type: "number" }],
      bicycleShelters: [],
      busStand: [],
      cab: [
        { fieldName: "avgDistancePerDay", type: "number" },
        { fieldName: "estimatedFleet", type: "number" },
      ],
      electricVehicle: [
        { fieldName: "estimatedFleet", type: "number" },
        { fieldName: "avgDistancePerDay", type: "number" },
      ],
      expressTrain: [
        { fieldName: "stationsCovered", type: "number" },
        { fieldName: "dailyRidershipCount", type: "number" },
      ],
      localTrain: [{ fieldName: "dailyRidershipCount", type: "number" }],
      metroTrain: [
        { fieldName: "dailyRidershipCount", type: "number" },
        { fieldName: "stationsCovered", type: "number" },
        { fieldName: "totalRoutesLength", type: "number" },
      ],
      metroStation: [{ fieldName: "expectedReach", type: "number" }],
      mobileVan: [
        { fieldName: "avgDistancePerDay", type: "number" },
        { fieldName: "totalOperationalHours", type: "number" },
      ],
      nonAcBus: [
        { fieldName: "estimatedFleet", type: "number" },
        { fieldName: "dailyRidershipCount", type: "number" },
        { fieldName: "operatorTypes", type: "string" },
        { fieldName: "operatorName", type: "string" },
        { fieldName: "travelType", type: "string" },
        { fieldName: "totalRoutesLength", type: "number" },
        { fieldName: "avgDistancePerDay", type: "number" },
      ],
      others: [],
      petrolPump: [],
      railwayStation: [
        { fieldName: "totalReach", type: "number" },
        { fieldName: "avgFootFall", type: "number" },
        { fieldName: "expectedReach", type: "number" },
        { fieldName: "travelType", type: "string" },
      ],
    },
    workAndEducation: {
      colleges: [
        { fieldName: "expectedReach", type: "number" },
        { fieldName: "totalReach", type: "number" },
      ],
      itPark: [
        { fieldName: "numOfEmployees", type: "number" },
        { fieldName: "numOfCompanies", type: "number" },
        { fieldName: "totalOperationalHours", type: "number" },
      ],
      notebooks: [],
      others: [],
      school: [{ fieldName: "expectedReach", type: "number" }],
      vendingMachine: [{ fieldName: "expectedReach", type: "number" }],
    },
    residential: {
      apartments: [
        { fieldName: "noOfImpressions", type: "number" },
        { fieldName: "approxReach", type: "number" },
        { fieldName: "noOfTowers", type: "number" },
        { fieldName: "noOfFlats", type: "number" },
        { fieldName: "screenNo", type: "number" },
        { fieldName: "screenSize", type: "string" },
        { fieldName: "ncssClass", type: "string" },
        { fieldName: "propertyPrice", type: "number" },
      ],
      electricityBills: [{ fieldName: "expectedReach", type: "number" }],
      gasBills: [{ fieldName: "expectedReach", type: "number" }],
      others: [],
      newspaperInsets: [{ fieldName: "totalReach", type: "number" }],
      payingGuest: [{ fieldName: "expectedReach", type: "number" }],
      noParkingBoard: [],
      default: [{ fieldName: "totalReach", type: "number" }],
    },
    healthAndFitness: {
      clinic: [
        { fieldName: "totalReach", type: "number" },
        { fieldName: "expectedReach", type: "number" },
        { fieldName: "screenNo", type: "number" },
      ],
      gyms: [{ fieldName: "totalReach", type: "number" }],
      others: [{ fieldName: "expectedReach", type: "number" }],
    },
    foodBeverageAndHospitality: {
      cafeCoffeeDay: [
        { fieldName: "totalReach", type: "number" },
        { fieldName: "expectedReach", type: "number" },
        { fieldName: "totalOperationalHours", type: "number" },
        { fieldName: "approxReach", type: "number" },
        { fieldName: "screenNo", type: "number" },
      ],
      hotel: [{ fieldName: "totalReach", type: "number" }],
      others: [{ fieldName: "totalReach", type: "number" }],
      restaurantCafeAndBar: [{ fieldName: "totalReach", type: "number" }],
      default: [{ fieldName: "totalReach", type: "number" }],
    },
    retailAndMall: {
      mall: [
        { fieldName: "expectedReach", type: "number" },
        { fieldName: "avgFootFall", type: "number" },
      ],
      relianceStores: [{ fieldName: "expectedReach", type: "number" }],
      default: [{ fieldName: "expectedReach", type: "number" }],
    },
  },

  // The remaining categories (airport, influencerMarketing, etc.)
  // follow the same structure as above with appropriate fields and types.
};

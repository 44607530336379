import { GingerMediaApiStorageBaseUrl } from "./baseUrls";
import { clientId } from "./baseUrls";

export async function uploadFileWithMetadata(authHeader : string, formData: FormData) {
    console.log("step2")
    return fetch(`${GingerMediaApiStorageBaseUrl}/uploadFileWithMetadata?clientId=${clientId}`,
        {
            method: 'POST',
            headers: {
                Authorization: authHeader,
              },
            body: formData
        }
    );
} 
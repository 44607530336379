import { fetchWrapper } from "./fetchWrapper";
import { GingerMediaApiStorageBaseUrl } from "./baseUrls";
export default function   deleteFileFromS3(authHeader:string,fileUrl:string){
 const url: string = `${GingerMediaApiStorageBaseUrl}/deleteFileFromS3`;
 return fetchWrapper(url,{
    method: "POST",
    body:{url:fileUrl},
    headers: {
      Authorization: authHeader,
    },})
}
import React, { useCallback, useEffect, useState } from "react";
import { uploadFileWithMetadata } from "../../clients/fsUuploadFileWithMetadata";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  GlobalStyles,
} from "@mui/material";
import {
  serviceCategoryMapper,
} from "../../utils/serviceNames";
import { createMediaDetails } from "../../clients/createMediaDetails";
import {
  extractImagesFromPptx,
  fileParser,
  handleDownloadSample1,
} from "./MediaDataUtils";
import {  metadataFields, tagFields } from "./MediaDataConstants";
import BulkMediaUploadPreview from "./BulkMediaDataPreview";
import UploadResultModal from "./UploadResultModal";
import { useAuthContext } from "../../providers/AuthProvider";

const MediaDataFile: React.FC = () => {
  const { authHeader } = useAuthContext();
  const [file, setFile] = useState<File | null>(null);
  const [pptxFile, setPptxFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string[][] | null>(null);
  const [pptxImages, setPptxImages] = useState<File[]>([]);
  const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
  const [isPptxSelected, setIsPptxSelected] = useState<boolean>(false);
  const [medium, setMedium] = useState<string>("");
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [uploadInitiated, setUploadInitiated] = useState(false);
  const [rowStatus, setRowStatus] = useState<
    Array<"not-initiated" | "uploading" | "success" | "failed">
  >(new Array(filePreview ? filePreview.length - 1 : 0).fill("not-initiated"));
  const [databaseColumns, setDatabaseColumns] = useState<string[]>([]);

  const [uploadResultOpen, setUploadResultOpen] = useState(false);
  const [failedCount, setFailedCount] = useState<number>(0);
  const [successCount, setSuccessCount] = useState<number>(0);

  const [service, setService] = useState<string | "">("");

  const [errorText, setErrorText] = useState<string>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (uploadInitiated || file || pptxFile) {
        const message =
          "You have unsaved changes or ongoing uploads. Are you sure you want to leave?";
        event.preventDefault();
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [uploadInitiated, file, pptxFile]);

  const rowImageCountMismatch = useCallback(() => {
    setError(true);
    setErrorText(
      "Number of rows in sheet do not match with number of images in ppt"
    );
    handleSnackbar("error", "number of rows in sheet and pptx do not match");
  }, []);

  const handleFilePreview = useCallback(
    async (uploadedFile: File) => {
      setFile(uploadedFile);
      setUploadInitiated(false);
      setDatabaseColumns([]);

      const parsedFile = await fileParser(uploadedFile);
      const headers = parsedFile.columns; // Assuming `columns` are the headers
      console.log({ headers });

      // Validate headers only if the service is selected
      if (service) {
        // const validationError = validateHeadersForService(service, headers);
        // if (validationError) {
        //   setError(true);
        //   setErrorText(validationError);
        //   handleSnackbar("error", validationError);
        //   return;
        // }

        // If validation passes, map the columns
        console.log({headers}, "HEADERS WHILE SETTINGS")
        setDatabaseColumns(headers);
        setFilePreview(parsedFile.filteredData);
        if (
          pptxImages &&
          pptxImages.length > 0 &&
          pptxImages.length !== parsedFile.filteredData.length - 1
        ) {
          rowImageCountMismatch();
        }
      }
    },
    [pptxImages, rowImageCountMismatch, service]
  );

  const handleFileChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.DragEvent<HTMLDivElement>,
    fileType: "excelCsv" | "pptx"
  ) => {
    if (!service || !medium) {
      setError(true); // Set error state
      handleSnackbar(
        "error",
        "Please select a service and medium before uploading a file."
      ); // Display snackbar
      handleRemoveFile();
      handleRemovePptx();
      setService("");
      setMedium("");
      setUploadInitiated(false);
      setDatabaseColumns([]);
      return; // Exit the function early
    }

    setError(false);
    setError(undefined);
    let uploadedFile: File | null = null;
    setUploadInitiated(false);

    if ("dataTransfer" in event && event.dataTransfer) {
      uploadedFile = event.dataTransfer.files?.[0] || null;
    } else if ("target" in event && event.target) {
      uploadedFile = (event.target as HTMLInputElement).files?.[0] || null;
    }

    if (uploadedFile) {
      if (fileType === "excelCsv") {
        setIsFileSelected(true);
        setFile(uploadedFile);
        handleFilePreview(uploadedFile);
      } else if (fileType === "pptx") {
        setIsPptxSelected(true);
        setPptxFile(uploadedFile);
        extractPptxImages(uploadedFile);
      }
    }
  };

  const extractPptxImages = async (pptxFile: File) => {
    try {
      const images = await extractImagesFromPptx(pptxFile);
      setPptxImages(images);
      if (
        filePreview &&
        filePreview.length > 0 &&
        filePreview.length - 1 !== images.length
      )
        rowImageCountMismatch();
    } catch (error) {
      console.error("Error extracting images from PPTX:", error);
    }
  };

  const updateRowStatus = useCallback(
    (
      status: "not-initiated" | "uploading" | "success" | "failed",
      index: number
    ) => {
      setRowStatus((prev) => {
        const newStatus = [...prev];
        newStatus[index - 1] = status;
        return newStatus;
      });
    },
    []
  );

  // const uploadRow = async (
  //   row: string[],
  //   index: number,
  //   successArray: any[],
  //   failedArray: any[]
  // ) => {
  //   if (!pptxImages[index - 1]) return;
  //   const mediaDetails: { [key: string]: string | null | number } = {};
  //   try {
  //     updateRowStatus("uploading", index);
  //     const imageData = new FormData();
  //     imageData.append("image", pptxImages[index - 1]);

  //     const additionalDetails: Record<string, string> = {};
  //     const metadata: any = {};
  //     const additionalFileData: { tags?: string; metadata?: string } = {};
  //     const tags: string[] = [];
  //     tags.push(medium);
  //     console.log({row})
  //     row.forEach((cell, cellIndex) => {
  //       console.log({databaseColumns, cellIndex, cell})
  //       const fieldName = databaseColumns[cellIndex];
  //       if (fieldName === "type" && cell) {
  //         mediaDetails[fieldName] = camelcase(cell, {
  //           pascalCase: false,
  //           preserveConsecutiveUppercase: false,
  //         });
  //       } else if (formatFields.includes(fieldName) && cell) {
  //         mediaDetails[fieldName] = cell;
  //       } else if (cell) {
  //         additionalDetails[fieldName] = cell;
  //       }
  //       if (metadataFields.includes(fieldName)) {
  //         metadata[`${fieldName}`] = cell;
  //       }
  //       if (tagFields.includes(fieldName)) {
  //         tags.push(cell);
  //       }
  //     });

  //     mediaDetails["additionalDetails"] =
  //       Object.keys(additionalDetails).length > 0
  //         ? JSON.stringify(additionalDetails)
  //         : null;

  //     if (Object.keys(metadata).length > 0) {
  //       additionalFileData.metadata = metadata;
  //     }
  //     if (tags.length > 0) {
  //       additionalFileData.tags = tags.join(", ");
  //     }
  //     imageData.append(
  //       "additionalFileData",
  //       JSON.stringify(additionalFileData)
  //     );
  //     if (authHeader) {
  //       const imageUploadResponse: any = await uploadFileWithMetadata(
  //         imageData
  //       );
  //       if (imageUploadResponse.ok) {
  //         const imageUrl = await imageUploadResponse.json();
  //         mediaDetails["imageUrl"] = imageUrl.uploadedFileData.fileUrl;
  //         console.log({mediaDetails,service,medium})

  //         const response = await createMediaDetails(
  //           service,
  //           medium,
  //           mediaDetails,
  //           authHeader
  //         );
  //         if (response.data) {
  //           handleSnackbar("success", `Row ${index} uploaded successfully`);
  //           successArray.push({
  //             index,
  //             row,
  //             response: response.data,
  //           });
  //           updateRowStatus("success", index);
  //         } else {
  //           handleSnackbar("error", "Row upload failed");
  //           failedArray.push({ index, row, error: "Row upload failed" });

  //           setRowStatus((prev) => {
  //             const newStatus = [...prev];
  //             newStatus[index - 1] = "failed";
  //             return newStatus;
  //           });
  //           throw new Error("Row upload failed");
  //         }
  //       } else {
  //         handleSnackbar("error", "Image upload failed");
  //         failedArray.push({ index, row, error: "Image upload failed" });
  //         updateRowStatus("failed", index);
  //         throw new Error("Image upload failed");
  //       }
  //     } else {
  //       throw new Error("User not recognized");
  //     }
  //   } catch (error) {
  //     failedArray.push({
  //       index,
  //       row,
  //       error: `Error uploading row ${index}: ${error}`,
  //     });
  //     updateRowStatus("failed", index);
  //     console.error(`Error uploading row ${index}:`, error);
  //     handleSnackbar("error", `Error uploading row ${index}`);
  //   }
  // };

  const uploadRow = async (
    row: string[],
    index: number,
    successArray: any[],
    failedArray: any[]
  ) => {
    try {
      updateRowStatus("uploading", index);
  
      // Prepare metadata directly from databaseColumns and row
      const mediaDetails: { [key: string]: string | null } = {};
      row.forEach((cell, cellIndex) => {
        const fieldName = databaseColumns[cellIndex];
        mediaDetails[fieldName] = cell || null; // Map column names directly to row values
      });
  
      // Add tags and metadata fields
      const tags: string[] = [];
      const metadata: { [key: string]: string | null } = {};
      row.forEach((cell, cellIndex) => {
        const fieldName = databaseColumns[cellIndex];
        if (tagFields.includes(fieldName) && cell) {
          tags.push(cell);
        }
        if (metadataFields.includes(fieldName) && cell) {
          metadata[fieldName] = cell;
        }
      });
  
      const additionalFileData: { tags?: string; metadata?: string } = {};
      if (tags.length > 0) {
        additionalFileData.tags = tags.join(", ");
      }
      if (Object.keys(metadata).length > 0) {
        additionalFileData.metadata = JSON.stringify(metadata);
      }
  
      // Prepare the image upload form
      const imageData = new FormData();
      imageData.append("files", pptxImages[index - 1]);
      // imageData.append("additionalFileData", JSON.stringify(additionalFileData));
      imageData.append("tags", "")
      imageData.append("metadata", "")
  
      if (authHeader) {
        // Upload the image and get the URL
        const imageUploadResponse: any = await uploadFileWithMetadata(authHeader, imageData);
        if (imageUploadResponse.ok) {
          const imageUrl = await imageUploadResponse.json();
          console.log({imageUrl})
          mediaDetails["imageUrl"] = imageUrl.fileData.fileUrl;
  
          // Send the metadata (mediaDetails) to the server
          const response = await createMediaDetails(
            service,
            medium,
            mediaDetails,
            authHeader
          );
          if (response.data) {
            handleSnackbar("success", `Row ${index} uploaded successfully`);
            successArray.push({
              index,
              row,
              response: response.data,
            });
            updateRowStatus("success", index);
          } else {
            throw new Error("Row upload failed");
          }
        } else {
          throw new Error("Image upload failed");
        }
      } else {
        throw new Error("User not recognized");
      }
    } catch (error) {
      failedArray.push({
        index,
        row,
        error: `Error uploading row ${index}: ${error}`,
      });
      updateRowStatus("failed", index);
      console.error(`Error uploading row ${index}:`, error);
      handleSnackbar("error", `Error uploading row ${index}`);
    }
  };
  
  const uploadAllRows = async () => {
    if (!filePreview || pptxImages.length === 0) return;
    setUploadInitiated(true);

    const successArray: any[] = [];
    const failedArray: any[] = [];
    const rowStatuses = new Array(filePreview.length - 1).fill("uploading");
    setRowStatus(rowStatuses);

    for (let i = 1; i < filePreview.length; i++) {
      await uploadRow(filePreview[i], i, successArray, failedArray);
    }

    setUploadResultOpen(true);
    setSuccessCount(successArray.length);
    setFailedCount(failedArray.length);
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFilePreview(null);
    setIsFileSelected(false);
    setUploadInitiated(false);
    setDatabaseColumns([]);
    setError(false);
    setErrorText(undefined);
  };

  const handleRemovePptx = () => {
    setPptxFile(null);
    setPptxImages([]);
    setIsPptxSelected(false);
    setUploadInitiated(false);
    setError(false);
    setErrorText(undefined);
  };

  const handleClearAll = () => {
    handleRemoveFile();
    handleRemovePptx();
    setService("");
    setMedium("");
    setUploadInitiated(false);
    setDatabaseColumns([]);
    setError(false);
    setErrorText(undefined);
  };

  const handleSnackbar = (
    severity: "success" | "error" | "warning" | "info",
    message: string
  ) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleUploadMore = () => {
    handleClearAll();
    setUploadResultOpen(false);
  };

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <>
      <GlobalStyles
        styles={{
          html: {
            overscrollBehaviorX: "none",
          },
          body: {
            overscrollBehaviorX: "none",
          },
        }}
      />
      <Box textAlign="center">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap="20px"
          mt={2}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap="10px"
          >
            {/* <FormControl sx={{ minWidth: "200px" }}>
              <InputLabel id="serviceLabel" sx={{ color: "" }}>
                Service
              </InputLabel>
              <Select
                labelId="serviceLabel"
                value={service}
                onChange={handleServiceChange}
                label="Service"
                required
              >
                {Object.values(ServiceNames).map(
                  (service: string, index: number) => (
                    <MenuItem key={index} value={service}>
                      {service.toUpperCase()}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: "200px" }}>
              <InputLabel id="mediumLabel" sx={{ color: "" }}>
                Medium
              </InputLabel>
              <Select
                labelId="mediumLabel"
                value={medium}
                onChange={handleMediumChange}
                label="Medium"
                required
              >
                {service &&
                  ServiceMediums[service]?.map(
                    (medium: string, index: number) => (
                      <MenuItem key={index} value={medium}>
                        {Mediums[medium as keyof typeof Mediums].toUpperCase()}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl> */}
            <FormControl sx={{ minWidth: "200px" }}>
              <InputLabel id="serviceLabel">Service</InputLabel>
              <Select
                labelId="serviceLabel"
                value={service}
                onChange={(e) => {
                  setService(e.target.value);
                }}
                label="Service"
                required
              >
                {Object.keys(serviceCategoryMapper).map((service) => (
                  <MenuItem key={service} value={service}>
                    {service}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: "200px" }}>
              <InputLabel id="categoryLabel">Category</InputLabel>
              <Select
                labelId="categoryLabel"
                value={medium}
                onChange={(e) => setMedium(e.target.value)}
                label="Category"
                required
                disabled={!service}
              >
                {service &&
                  serviceCategoryMapper[service]?.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button
              size="large"
              onClick={() => handleDownloadSample1(service, medium)}
              variant="contained"
              disabled={!service}
            >
              Download {service || "Service"} Template
            </Button>
          </Box>
          <Box
            border="2px dashed "
            borderRadius="10px"
            padding="20px"
            color=""
            height="150px"
            width="20%"
            position="relative"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            onDrop={(event) => handleFileChange(event, "excelCsv")}
            onDragOver={(event) => event.preventDefault()}
          >
            {!file && (
              <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(event) => handleFileChange(event, "excelCsv")}
                style={{ display: "none" }}
                id="fileInput"
              />
            )}
            <label
              htmlFor="fileInput"
              style={{
                cursor: file ? "not-allowed" : "pointer",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {file ? (
                <Typography variant="h6">
                  File Selected: <br />
                  {file.name}
                </Typography>
              ) : (
                <Typography>
                  Drag & Drop your CSV/Excel file here or click to select
                </Typography>
              )}
            </label>
            {file && (
              <Box position="absolute" bottom="10px" display="flex" gap="10px">
                <Button onClick={handleRemoveFile}>Remove File</Button>
              </Box>
            )}
          </Box>
          <Box
            border="2px dashed "
            borderRadius="10px"
            padding="20px"
            color=""
            height="150px"
            width="20%"
            position="relative"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            onDrop={(event) => handleFileChange(event, "pptx")}
            onDragOver={(event) => event.preventDefault()}
          >
            {!pptxFile && (
              <input
                type="file"
                accept=".pptx"
                onChange={(event) => handleFileChange(event, "pptx")}
                style={{ display: "none" }}
                id="pptxInput"
              />
            )}
            <label
              htmlFor="pptxInput"
              style={{
                cursor: pptxFile ? "not-allowed" : "pointer",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {pptxFile ? (
                <Typography variant="h6">
                  File Selected: <br />
                  {pptxFile.name}
                </Typography>
              ) : (
                <Typography>
                  Drag & Drop your PowerPoint file here or click to select
                </Typography>
              )}
            </label>
            {pptxFile && (
              <Box position="absolute" bottom="10px" display="flex" gap="10px">
                <Button onClick={handleRemovePptx}>Remove File</Button>
              </Box>
            )}
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            paddingTop="15px"
          >
            <Button size="large" onClick={handleClearAll} variant="contained">
              Clear All
            </Button>
          </Box>
        </Box>

        {file && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              maxWidth="90%"
              margin="20px auto"
            >
              <Typography variant="h5">Preview</Typography>
              {errorText && <Typography color="error">{errorText}</Typography>}
              <Button
                variant="contained"
                onClick={uploadAllRows}
                disabled={
                  !isFileSelected ||
                  !isPptxSelected ||
                  !service ||
                  !medium ||
                  uploadInitiated ||
                  error
                }
              >
                Upload
              </Button>
            </Box>
            <BulkMediaUploadPreview
              filePreview={filePreview}
              uploadInitiated={uploadInitiated}
              rowStatus={rowStatus}
              pptxImages={pptxImages}
            />
          </>
        )}
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
        <UploadResultModal
          open={uploadResultOpen}
          onClose={() => setUploadResultOpen(false)}
          successCount={successCount}
          failedCount={failedCount}
          onUploadMore={handleUploadMore}
          onGoBack={handleGoBack}
        />
      </Box>
    </>
  );
};

export default MediaDataFile;

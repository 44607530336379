import { GingerMediaBaseUrl } from "./baseUrls";
import { fetchWrapper } from "./fetchWrapper";

export async function createMediaDetails(
  service: string,
  medium: string,
  formValues: any,
  authHeader: string
) {
  const url = `${GingerMediaBaseUrl}/createAdvertisingMediaDetails?service=${service}&category=${medium}`;
  return fetchWrapper(url, {
    method: "POST",
    headers: {
      Authorization: authHeader,
    },
    body: formValues,
  });
}
import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  Snackbar,
  Alert,
  Box,
  Divider,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  OutlinedInput,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { createMediaDetails } from "../../../clients/createMediaDetails";
import { useAuthContext } from "../../../providers/AuthProvider";
import { serviceCategoryMapper } from "../../../utils/serviceNames";
import { useImageUpload } from "./ImageComponent";
import {
  commonFields,
  subCategoryKeyInsightMapper,
  subCategoryKeyInsightMapperWithType,
} from "./AdvertisingMediaTypes";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ServiceCategoryUI = () => {
  const [service, setService] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [subCategory, setSubCategory] = useState<string>("");
  const [fields, setFields] = useState<
    Array<{ fieldName: string; type: string }>
  >([]);
  const [fieldValues, setFieldValues] = useState<Record<string, string>>({});
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarType, setSnackbarType] = useState<"success" | "error">(
    "success"
  );
  const [open, setOpen] = React.useState(false);
  const [state, actions] = useImageUpload();
  const [isUploading, setIsUploading] = useState(false);

  const { authHeader } = useAuthContext();

  const handleServiceChange = (event: SelectChangeEvent<string>) => {
    const selectedService = event.target.value as string;
    setService(selectedService);
    setCategory(""); // Reset category on service change
    setSubCategory(""); // Reset subcategory on service change
    setFields([]); // Reset fields on service change
    setFieldValues({}); // Reset field values on service change
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory);
    setSubCategory(""); // Reset subcategory on category change
    setFields([]); // Reset fields on category change
    setFieldValues({}); // Reset field values on category change
  };

  const handleSubCategoryChange = (event: SelectChangeEvent<string>) => {
    const selectedSubCategory = event.target.value;
    setSubCategory(selectedSubCategory);

    const derivedFields = getFields(service, category, selectedSubCategory);
    setFields(derivedFields);

    setFieldValues({
      subCategory: selectedSubCategory,
      ...commonFields.reduce((acc, field) => {
        acc[field.fieldName] = "";
        return acc;
      }, {} as Record<string, string>),
      ...derivedFields.reduce((acc, field) => {
        acc[field.fieldName] = "";
        return acc;
      }, {} as Record<string, string>),
    });
  };

  const getFields = (
    service: string,
    category: string,
    subCategory: string
  ): { fieldName: string; type: string }[] => {
    if (
      subCategoryKeyInsightMapper[service] &&
      subCategoryKeyInsightMapper[service][category] &&
      subCategoryKeyInsightMapper[service][category][subCategory]
    ) {
      return subCategoryKeyInsightMapperWithType[service][category][
        subCategory
      ];
    }
    return [];
  };

  //   const handleFieldChange = (field: string, value: string) => {
  //     setFieldValues((prevValues) => ({
  //       ...prevValues,
  //       [field]: value,
  //     }));
  //   };

  const handleFieldChange = (field: string, value: string | File) => {
    console.log({ field, value });
    if (field === "imageUrl") {
      // Handle image upload
      if (value instanceof File) {
        setIsUploading(true);
      } else {
        setFieldValues((prevValues) => ({ ...prevValues, imageUrl: value }));
      }
    } else {
      setFieldValues((prevValues) => ({
        ...prevValues,
        [field]: value.toString(),
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!authHeader) return;
    setIsUploading(true);

    try {
      const res = await createMediaDetails(
        service,
        category,
        { ...fieldValues, imageUrl: state.imageUrl },
        authHeader
      );
      console.log({ res });
      setSnackbarMessage("Media details submitted successfully!");
      setSnackbarType("success");
      handleClickOpen();
      setIsUploading(false);
    } catch (error) {
      console.error(error);
      setSnackbarMessage("Failed to submit media details.");
      setSnackbarType("error");
      setIsUploading(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFieldValues({});
    actions.reset();
  };

  return (
    <Container maxWidth="lg" sx={{ pt: 5, pb: 5 }}>
      <Box sx={{ pt: 5, pb: 5, px: 2 }}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom sx={{ textAlign: "center" }}>
              <strong>Media Details Form</strong>
            </Typography>
            <Divider sx={{ mb: 3 }} />

            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="serviceLabel">Service</InputLabel>
                    <Select
                      labelId="serviceLabel"
                      value={service}
                      onChange={handleServiceChange}
                      label="Service"
                    >
                      {Object.keys(serviceCategoryMapper).map(
                        (serviceKey, index) => (
                          <MenuItem key={index} value={serviceKey}>
                            {serviceKey.toUpperCase()}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                {service && (
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel id="categoryLabel">Category</InputLabel>
                      <Select
                        labelId="categoryLabel"
                        value={category}
                        onChange={handleCategoryChange}
                        label="Category"
                      >
                        {serviceCategoryMapper[service].map(
                          (categoryKey, index) => (
                            <MenuItem key={index} value={categoryKey}>
                              {categoryKey.toUpperCase()}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {category && (
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel id="subCategoryLabel">Subcategory</InputLabel>
                      <Select
                        labelId="subCategoryLabel"
                        value={subCategory}
                        onChange={handleSubCategoryChange}
                        label="Subcategory"
                      >
                        {Object.keys(
                          subCategoryKeyInsightMapperWithType[service]?.[
                            category
                          ] || {}
                        ).map((subCategoryKey, index) => (
                          <MenuItem key={index} value={subCategoryKey}>
                            {subCategoryKey.toUpperCase()}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>

              {fields.length > 0 && (
                <Box sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    {[...commonFields, ...fields].map((field, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        {field.type === "textarea" ? (
                          <TextField
                            fullWidth
                            label={field.fieldName}
                            variant="outlined"
                            multiline
                            rows={4}
                            value={fieldValues[field.fieldName] || ""}
                            onChange={(e) =>
                              handleFieldChange(field.fieldName, e.target.value)
                            }
                          />
                        ) : (
                          <TextField
                            fullWidth
                            label={field.fieldName}
                            variant="outlined"
                            type={field.type}
                            value={fieldValues[field.fieldName] || ""}
                            onChange={(e) =>
                              handleFieldChange(field.fieldName, e.target.value)
                            }
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth variant="outlined" focused>
                  <InputLabel htmlFor="file-input">Upload Image</InputLabel>
                  <OutlinedInput
                    id="file-input"
                    type="file"
                    onChange={(e) => {
                      const input = e.target as HTMLInputElement;
                      actions.handleImageUpload(input.files![0]);
                    }}
                    inputProps={{
                      "aria-label": "upload picture",
                    }}
                  />
                </FormControl>
              </Box>

              <Box sx={{ mt: 4, textAlign: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isUploading || state.isUploading}
                >
                  Submit
                </Button>
              </Box>
            </form>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={actions.reset}
            >
              reset
            </Button>
          </CardContent>
        </Card>

        {snackbarMessage && (
          <Snackbar
            open={Boolean(snackbarMessage)}
            autoHideDuration={4000}
            onClose={() => setSnackbarMessage(null)}
          >
            <Alert
              severity={snackbarType}
              sx={{ width: "100%" }}
              onClose={() => setSnackbarMessage(null)}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        )}
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Advertising Media Details for {category} is uploaded successfully
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={handleClose}>upload more</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ServiceCategoryUI;

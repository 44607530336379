import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate } from "react-router-dom";

const EditMediaNavigation: React.FC = () => {
  const navigate = useNavigate();
  const handleMediaForm = () => {
    navigate("/mediaForm");
  };

  const handleAdvertisingMedia = () => {
    navigate(`/advertisingMedia`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: `calc(100vh - 90px)`,
        gap: "20px",
      }}
    >
      <Button
        size="large"
        variant="contained"
        startIcon={<RecentActorsIcon />}
        onClick={handleMediaForm}
      >
        Add Media Form
      </Button>
      <Button
        size="large"
        variant="contained"
        startIcon={<PersonAddIcon />}
        onClick={handleAdvertisingMedia}
      >
        Edit Advertising Media 
      </Button>
    </Box>
  );
};

export default EditMediaNavigation;

import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { AdvertisingOptions } from '../../../clients/getAdvertisingMediaByService';
import EditAdvertisingOption from './EditAdvertisingOption'; // Adjust the import path as necessary

interface AdvertisingOptionCardProps {
  option: AdvertisingOptions;
  gmgAssetCode:string;
}

const AdvertisingOptionCard: React.FC<AdvertisingOptionCardProps> = ({ option,gmgAssetCode }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [edit, setEdit] = useState<boolean>(false);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % option.imageUrls.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + option.imageUrls.length) % option.imageUrls.length);
  };

  useEffect(() => {
    if (option.imageUrls.length > 1) {
      const interval = setInterval(nextImage, 3000); // Change image every 3 seconds
      return () => clearInterval(interval);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option.imageUrls.length]);

  const handleEdit = () => {
    setEdit(true);
  };

  const handleClose = () => {
    setEdit(false);
  };

  return (
    <>
      <Card sx={{ maxWidth: 345, margin: 'auto', boxShadow: 3, borderRadius: 2 }}>
        {option.imageUrls && option.imageUrls.length > 0 && (
          <div style={{ position: 'relative', width: '100%', height: '200px', overflow: 'hidden', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
            <img
              src={option.imageUrls[currentImageIndex]}
              alt={`Slide ${currentImageIndex}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            {option.imageUrls.length > 1 && (
              <>
                <IconButton
                  onClick={prevImage}
                  style={{ position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
                >
                  <ArrowBackIos />
                </IconButton>
                <IconButton
                  onClick={nextImage}
                  style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
                >
                  <ArrowForwardIos />
                </IconButton>
              </>
            )}
          </div>
        )}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
            {option.title}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Min Billing: {option.minBilling}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Price: {option.price}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleEdit} fullWidth>
            EDIT
          </Button>
        </CardContent>
      </Card>

      <Dialog
        open={edit}
        onClose={handleClose}
        fullScreen
      >
        <DialogTitle>Edit Advertising Option</DialogTitle>
        <DialogContent>
          <EditAdvertisingOption option={option} gmgAssetCode={gmgAssetCode} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdvertisingOptionCard;
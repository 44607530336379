export enum ServiceNames {
  outdoor = "outdoor",
  airlines = "airlines",
  airport = "airport",
  newspaper = "newspaper",
  radio = "radio",
  sports = "sports",
  mall = "mall",
  cinema = "cinema",
  digital = "digital",
  transit = "transit",
  magazine = "magazine",
  television = "television",
  btl = "btl",
}

export enum Mediums {
  busShelter = "Bus Shelter",
  unipole = "Unipole",
  billboard = "Bill Board",
}

export const ServiceMediums = {
  outdoor: ["busShelter", "unipole", "billboard"],
};

export const DigitalMedium = {
  digital: ["digitalscreen", "staticframes"],
};

export const serviceCategoryMapper: Record<string, string[]> = {
  nonTraditional: [
    "transit",
    "workAndEducation",
    "residential",
    "healthAndFitness",
    "foodBeverageAndHospitality",
    "retailAndMall",
  ],
  transit: [
    "acBus",
    "nonAcBus",
    "auto",
    "bicycleShelters",
    "busStand",
    "busStand",
    "electricVehicle",
    "metroTrain",
    "localTrain",
    "metroStation",
    "railwayStation",
    "metroPillar"
  ],
  airport: ["airport", "airline"],
  influencerMarketing: ["default"],
  magazine: ["news", "default"],
  radio: ["radio", "default"],
  television: ["default"],
  cinema: ["cinema", "video", "default"],
  digital: ["business", "default"],
  outdoor: ["hoarding", "default"],
  newspaper: ["newspaper", "default"],
  sports: ["default"],
  mall: ["retailAndMall"],
  inflight: ["airline"],
};

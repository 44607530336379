import { fetchWrapper } from "./fetchWrapper";
import { GingerMediaBaseUrl } from "./baseUrls";

export async function getAdvertisingMediaById(
  authHeader: string,
  gmgAssetCode: string,
) {
  return fetchWrapper(
    `${GingerMediaBaseUrl}/getAdvertisingMediaByIdForAdmin?gmgAssetCode=${gmgAssetCode}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
    }
  );
}
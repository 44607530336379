import { fetchWrapper } from "@gogig-in/gogig-clients";
import { clientId, GingerMediaApiStorageBaseUrl } from "./baseUrls";

export default function generatePresignedUrls(
  fileId: string,
  id: string,
  parts: number
) {
  return fetchWrapper(`${GingerMediaApiStorageBaseUrl}/generatePresignedUrls?clientId=${clientId}`, {
    method: "POST",
    body: {
      fileId: fileId,
      uploadId: id,
      parts: parts,
    },
  });
}
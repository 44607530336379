// import { useEffect, useState } from "react";
// import {
//   getAdvertisingMediaByService,
//   MediaItem,
// } from "../../../clients/getAdvertisingMediaByService";
// import {
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Card,
//   Box,
//   Grid,
//   Button,
//   CardContent,
//   Divider,
//   Container,
// } from "@mui/material";
// import { serviceCategoryMapper } from "../../../utils/serviceNames";

// const ServiceNameMapper: Record<string, string> = {
//   outdoor: "outdoor",
//   digital: "Digital",
//   cinema: "cinema",
//   nonTraditional: "Non Traditional",
//   radio: "radio",
//   newspaper: "newsPaper",
//   magazine: "magazine",
//   mall: "mall",
//   sports: "sports",
//   influencerMarketing: "Influencer Marketing",
//   television: "television",
//   airport: "airport",
// };

// const AdvertisingMediaDetails = () => {
//   const [mediaData, setMediaData] = useState<MediaItem[]>([]);
//   const [service, setService] = useState<string>("");
//   const [offset, setOffset] = useState<number>(0);
//   const [totalCount, setTotalCount] = useState<number>(0);

//   useEffect(() => {
//     if (service) {
//       setMediaData([]); // Reset media data when service changes
//       setOffset(0);
//       fetchAdvertisingMedia();
//     }
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [service]);

//   const fetchAdvertisingMedia = async () => {
//     try {
//       if (service) {
//         const response = await getAdvertisingMediaByService(service, offset);

//         const data: MediaItem[] = response?.data as MediaItem[];
//         setMediaData((prevMediaData) => [...prevMediaData, ...data]);

//         const nextOffset = response?.nextOffset;
//         setOffset(nextOffset ?? 0);

//         const totalResults = response?.totalCount;
//         setTotalCount(totalResults ?? 0);
//       }
//     } catch (error) {
//       alert(`Something went wrong, ${error}`);
//       console.error("Error fetching media data:", error);
//     }
//   };

//   return (
//     <>
//       <Container>
//         <Typography variant="h4" gutterBottom>
//           Advertising Media Details
//         </Typography>

//         <FormControl fullWidth margin="normal">
//           <InputLabel id="serviceLabel">Select Service</InputLabel>
//           <Select
//             labelId="serviceLabel"
//             value={service === null ? "" : service}
//             onChange={(event) =>
//               setService(event.target.value as string)
//             }
//             label="Select Service"
//           >
//             <MenuItem value="">Select Service</MenuItem>
//             {Object.keys(serviceCategoryMapper).map((service) => (
//               <MenuItem key={service} value={service}>
//                 {ServiceNameMapper[service] || service}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       </Container>

//       <Box mt={3}>
//         <Box display="flex" justifyContent="space-between" px={4}>
//           <Typography variant="h6">Media Items:</Typography>
//           <Typography variant="h5" gutterBottom>
//             Results : {totalCount}
//           </Typography>
//         </Box>

//         {mediaData.length === 0 ? (
//           <Typography variant="body1" textAlign={"center"}>
//             No media items found.
//           </Typography>
//         ) : (
//           <Grid container spacing={2}>
//             {mediaData.map((item, index) => (
//               <Grid item xs={12} sm={6} md={3} key={index}>
//                 <Card
//                   sx={{
//                     height: "100%",
//                     display: "flex",
//                     flexDirection: "column",
//                     boxShadow:5,
//                   }}
//                 >
//                   {item.imageUrl && (
//                     <CardContent sx={{ flexGrow: 1 }}>
//                       <img
//                         src={item.imageUrl}
//                         alt={`Media item for ${item.service}`}
//                         style={{ width: "100%", height: "180px", objectFit:'cover' }}
//                       />
//                     </CardContent>
//                   )}

//                   <CardContent sx={{ flexGrow: 1 }}>
//                     <Typography variant="h6">
//                       {ServiceNameMapper[item.service]}
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       Category: {item.category}
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       Sub Category: {item.subCategory || "N"}
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       Location: {item.location}
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       Area: {item.area || "N/A"}
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       City: {item.city || "N/A"}
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       Price: {item.price ?? "N/A"}
//                     </Typography>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             ))}
//           </Grid>
//         )}
//       </Box>

//       {mediaData.length !== 0 && (
//         <>
//           <Divider
//             sx={{
//               borderBottomWidth: 10,
//             }}
//           />
//           <Box
//             m={3}
//             display="flex"
//             justifyContent="space-between"
//             alignItems="center"
//           >
//             <Button
//               onClick={() => setOffset((prev) => prev - 10)}
//               disabled={offset === 0}
//             >
//               {/* Previous */}
//             </Button>
//             <Typography variant="h6">
//               Page {Math.ceil(mediaData.length / 20)}
//             </Typography>
//             <Button onClick={() => fetchAdvertisingMedia()} variant="contained" disabled={offset===0}>
//               Next
//             </Button>
//           </Box>
//         </>
//       )}
//     </>
//   );
// };

// export default AdvertisingMediaDetails;
import { useEffect, useState } from "react";
import {
  getAdvertisingMediaByService,
  MediaItem,
} from "../../../clients/getAdvertisingMediaByService";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Card,
  Box,
  Grid,
  Button,
  CardContent,
  Divider,
  Container,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import { serviceCategoryMapper } from "../../../utils/serviceNames";
import { useNavigate } from "react-router-dom";

const ServiceNameMapper: Record<string, string> = {
  outdoor: "Outdoor",
  digital: "Digital",
  cinema: "Cinema",
  nonTraditional: "Non Traditional",
  radio: "Radio",
  newspaper: "Newspaper",
  magazine: "Magazine",
  mall: "Mall",
  sports: "Sports",
  influencerMarketing: "Influencer Marketing",
  television: "Television",
  airport: "Airport",
};

const AdvertisingMediaDetails = () => {
  const [mediaData, setMediaData] = useState<MediaItem[]>([]);
  const [service, setService] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
 const navigate= useNavigate();
  useEffect(() => {
    if (service) {
      resetMediaData(); // Clear data on service change
      fetchAdvertisingMedia(0); // Fetch first page
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  const resetMediaData = () => {
    setMediaData([]);
    setOffset(0);
    setTotalCount(0);
  };

  const fetchAdvertisingMedia = async (currentOffset: number) => {
    setLoading(true);
    try {
      const response = await getAdvertisingMediaByService(
        service,
        currentOffset
      );
      const data: MediaItem[] = response?.data || [];
      console.log(data);
      setMediaData((prev) => [...prev, ...data]);
      setOffset(response?.nextOffset || 0);
      setTotalCount(response?.totalCount || 0);
    } catch (error) {
      alert(`Failed to fetch media data: ${error}`);
      console.error("Error fetching media data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    fetchAdvertisingMedia(offset);
  };


  const handleEditClick = (item: MediaItem) => {
    navigate(`/editMediaDetails/${item.gmgAssetCode}`)
  };

  return (
    <>
      <Dialog open={loading}>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        </DialogContent>
    </Dialog>

      <Container>
        <Typography variant="h4" gutterBottom>
          Advertising Media Details
        </Typography>

        <FormControl fullWidth margin="normal">
          <InputLabel id="serviceLabel">Select Service</InputLabel>
          <Select
            labelId="serviceLabel"
            value={service}
            onChange={(event) => setService(event.target.value)}
            label="Select Service"
          >
            <MenuItem value="">Select Service</MenuItem>
            {Object.keys(serviceCategoryMapper).map((serviceKey) => (
              <MenuItem key={serviceKey} value={serviceKey}>
                {ServiceNameMapper[serviceKey] || serviceKey}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box mt={3}>
          <Box display="flex" justifyContent="space-between" px={2}>
            <Typography variant="h6">Media Items:</Typography>
            <Typography variant="h6">Results: {totalCount}</Typography>
          </Box>

          {mediaData.length === 0 ? (
            <Typography variant="body1" textAlign="center" mt={3}>
              No media items found.
            </Typography>
          ) : (
            <Grid container spacing={2}>
              {mediaData.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      boxShadow: 5,
                    }}
                  >
                    {item.imageUrl && (
                      <Box sx={{ height: "180px", overflow: "hidden" }}>
                        <img
                          src={item.imageUrl}
                          alt={`Media item for ${item.service}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    )}

                    <CardContent>
                      <Typography>{item.gmgAssetCode}</Typography>
                      <Typography variant="h6">
                        {ServiceNameMapper[item.service]}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Category: {item.category || "N/A"}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Sub Category: {item.subCategory || "N/A"}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Location: {item.location || "N/A"}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Area: {item.area || "N/A"}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        City: {item.city || "N/A"}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Price: {item.price ?? "N/A"}
                      </Typography>
                    </CardContent>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEditClick(item)}
                    >
                      Edit
                    </Button>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        {mediaData.length > 0 && (
          <>
            <Divider sx={{ borderBottomWidth: 10 }} />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              m={2}
            >
              <Button
                variant="contained"
                color="primary"
              >
              </Button>
              <Typography variant="h6">
                Page {Math.ceil((offset + mediaData.length) / 20)}
              </Typography>
              <Button
                onClick={handleNext}
                variant="contained"
                disabled={offset === 0}
              >
                Next
              </Button>
            </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default AdvertisingMediaDetails;

import { GingerMediaBaseUrl } from "./baseUrls";
import { fetchWrapper } from "./fetchWrapper";

export async function updateAdvertisingOptions(
  adOptionType:string,
  adoptionId:number,
  gmgAssetCode: string,
  payloadData:any,
) {
 
    const url = `${GingerMediaBaseUrl}/updateAdvertisingOptions?adOptionType=${adOptionType}&adoptionId=${adoptionId}&gmgAssetCode=${gmgAssetCode}`;
    
    return fetchWrapper(url, {
      method: "PATCH",
      body: payloadData, // Send the filtered payload
    });
  }
  


import React, { useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  TextField,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/CloudUpload";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useFileUpload from "../../../utils/uploadFileHook";
import { uploadFileWithMetadata } from "../../../clients/fsUuploadFileWithMetadata";
import { useAuthContext } from "../../../providers/AuthProvider";

const fileSize = 5 * 1024 * 1024;

const FileUploader: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>("");
  const [, setUploadProgress] = useState<Record<string, number>>({});
  const deletingIndexRef = useRef<number | null>(null);
  const { uploadFile, loading, setLoading, error } = useFileUpload(
    setUploadProgress,
    deletingIndexRef
  );
  const { authHeader } = useAuthContext();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      setFile(event.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  console.log({ error });
  const handleUpload = async () => {
    if (!file || !authHeader) return;

    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      if (file) {
        if (file.size <= fileSize) {
          const response = await uploadFileWithMetadata(authHeader, formData);
          const imageUrl = await response.json();
          
          setFileUrl(imageUrl.fileData.fileUrl ?? "");
        } else {
          const response = await uploadFile(1, file);
          setFileUrl(response);
        }
      }
    } catch (error) {
      console.error("Upload failed", error);
      alert("File upload failed");
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(fileUrl ?? "");
    alert("URL copied to clipboard");
  };

  const handleClear = () => {
    setFile(null);
    setFileUrl("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "60vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          border: "2px dashed #1976d2",
          padding: 3,
          borderRadius: 2,
          width: "400px",
          margin: "auto",
          textAlign: "center",
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <Typography variant="h6" gutterBottom>
          FILE UPLOADER
        </Typography>
        {!file && (
          <>
            <input
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-input"
            />

            <label htmlFor="file-input" style={{ width: "100%" }}>
              <Box
                sx={{
                  border: "1px solid #ccc",
                  padding: 4,
                  borderRadius: 2,
                  cursor: "pointer",
                  backgroundColor: "#f9f9f9",
                  "&:hover": { backgroundColor: "#f0f0f0" },
                }}
              >
                <FileUploadIcon fontSize="large" />
                <Typography variant="body1">
                  Drag & Drop or Click to Select File
                </Typography>
              </Box>
            </label>
          </>
        )}

        {file && (
          <>
            <Typography variant="body1" my={2}>
              FILE NAME - {file.name}
            </Typography>
            <Button
              onClick={handleClear}
              fullWidth
              variant="outlined"
              color="primary"
            >
              Clear
            </Button>
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleUpload}
          disabled={!file || loading}
          sx={{ mt: 2 }}
        >
          {loading ? <CircularProgress size={24} /> : "Upload"}
        </Button>
        {fileUrl && (
          <Box sx={{ mt: 2, width: "100%", maxWidth: 400 }}>
            <TextField
              value={fileUrl}
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <IconButton onClick={handleCopy}>
                    <ContentCopyIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FileUploader;

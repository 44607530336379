import { GingerMediaBaseUrl } from "./baseUrls";
import { fetchWrapper } from "./fetchWrapper";
import { MediaItem } from "./getAdvertisingMediaByService";

export async function updateAdvertisingMediaDetails(
  service: string,
  category: string,
  gmgAssetCode: string,
  formValues: MediaItem | null,
  authHeader: string
) {
  // Create a new object excluding the specified fields
  if (formValues) {
    const { title: ___,advertisingOptions, category: _, gmgAssetCode: __, ...restData  } = formValues;
    const transformedKeyInsights = restData.keyInsights?.reduce((acc, curr) => ({
      ...acc,
      [curr.key]: curr.value
  }), {});

  // Create the final payload
  const payloadData = {
      ...restData,
      ...transformedKeyInsights,  // Spread the transformed insights as direct properties
      keyInsights: undefined  // Remove the original keyInsights array
  };
    
    const url = `${GingerMediaBaseUrl}/updateAdvertisingMediaDetails?gmgAssetCode=${gmgAssetCode}&service=${service}&category=${category}`;
    
    return fetchWrapper(url, {
      method: "PATCH",
      headers: {
        Authorization: authHeader,
      },
      body: payloadData, // Send the filtered payload
    });
  }
  
  throw new Error("Form values are required");
}

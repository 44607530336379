import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GroupsIcon from "@mui/icons-material/Groups";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import UploadFileIcon from "@mui/icons-material/UploadFile"; // Import this at the top
import { useAuthContext } from "../../providers/AuthProvider";
import UnauthorizedMessage from "./UnauthorizedMessage";

const Home = () => {
  const navigate = useNavigate();
  const { isAuthenticated, userDetails } = useAuthContext();

  const IconButtonStyle = styled(Button)(({ theme }) => ({
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.primary.main,
    cursor: "pointer",
    margin: "0 40px",
    transition: "transform 0.1s",
    "&:hover": {
      backgroundColor: "transparent",
      transform: "scale(1.2)",
    },
  }));

  // Define role-based access rules
  const role = userDetails?.role;
  const isAdmin = role === "gogig-role-a1";
  const isLimitedUser = role === "gogig-role-o1";

  // Navigation items with role-based visibility
  const navigationItems = [
    {
      label: "Admin Panel",
      icon: <AdminPanelSettingsIcon sx={{ fontSize: 100 }} />,
      onClick: () => navigate("/wordpress-admin"),
      visible: isAdmin, // Only for Admins
    },
    {
      label: "Services",
      icon: <MiscellaneousServicesIcon sx={{ fontSize: 100 }} />,
      onClick: () => navigate("/editMediaNavigation"),
      visible: isAdmin || isLimitedUser, // Admin & Limited Users
    },
    {
      label: "Vendors",
      icon: <GroupsIcon sx={{ fontSize: 100 }} />,
      onClick: () => navigate("/vendors"),
      visible: isAdmin, // Only for Admins
    },
    {
      label: "Uploads",
      icon: <CloudUploadIcon sx={{ fontSize: 100 }} />,
      onClick: () => navigate("/upload"),
      visible: isAdmin || isLimitedUser, // Admin & Limited Users
    },
    {
      label: "File Upload",
      icon: <UploadFileIcon sx={{ fontSize: 100 }} />,
      onClick: () => navigate("/file-upload"),
      visible: isAdmin || isLimitedUser, // Admin & Limited Users
    },
  ];

  return isAuthenticated ? (
    <Box
      sx={{
        height: `calc(100vh - 90px)`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          {navigationItems
            .filter((item) => item.visible)
            .map((item, index) => (
              <IconButtonStyle key={index} onClick={item.onClick}>
                {item.icon}
                <Typography marginTop={1} variant="body1" fontWeight="bold">
                  {item.label}
                </Typography>
              </IconButtonStyle>
            ))}
        </Box>
      </Box>
    </Box>
  ) : (
    <UnauthorizedMessage />
  );
};

export default Home;

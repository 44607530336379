import { fetchWrapper } from "@gogig-in/gogig-clients";
import { clientId, GingerMediaApiStorageBaseUrl } from "./baseUrls";

export interface UploadedPart {
  ETag: string | null;
  PartNumber: number;
}

export default function completeFileUpload(
  fileId: string,
  uploadId: string,
  parts: UploadedPart[]
) {
  return fetchWrapper(`${GingerMediaApiStorageBaseUrl}/completeFileUpload?clientId=${clientId}`, {
    method: "POST",
    body: {
      fileId: fileId,
      uploadId: uploadId,
      parts: parts,
    },
  });
}

import React, { useState, useEffect, useCallback } from "react";
import {
  DialogContent,
  TextField,
  Box,
  Typography,
  Button,
  CircularProgress,
  AppBar,
  Toolbar,
  Container,
  Grid,
  styled,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { MediaItem } from "../../../clients/getAdvertisingMediaByService";
import { getAdvertisingMediaById } from "../../../clients/getAdvertisingMediaById";
import { updateAdvertisingMediaDetails } from "../../../clients/updateAdvertisingMediaDetail";
import { useAuthContext } from "../../../providers/AuthProvider";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { uploadFileWithMetadata } from "../../../clients/fsUuploadFileWithMetadata";
import deleteFileFromS3 from "../../../clients/deleteFileFromS3";
import { useParams } from "react-router-dom";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import { marked } from "marked";
import AdvertisingOptionsList from "./AdvertisingOptionsList";

// Styled Components
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ImageUploadBox = styled(Paper)(({ theme }) => ({
  width: 300,
  height: 200,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  border: `2px dashed ${theme.palette.grey[400]}`,
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    borderColor: theme.palette.primary.main,
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
    },
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "inline-block",
  "&:hover .overlay": {
    opacity: 1,
  },
}));

const ImageOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0,
  transition: theme.transitions.create("opacity"),
}));

// Standalone FAQ Editor Component
const FAQEditor = ({ value, onChange }: { value: string; onChange: (value: string) => void }) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        FAQ Markdown Editor
      </Typography>
      <MdEditor
        value={value}
        onChange={({ text }) => onChange(text)}
        style={{ height: "400px" }}
        renderHTML={(text) => marked(text)}
      />
    </Box>
  );
};

const EditMediaDialog = () => {
  const [editedItem, setEditedItem] = useState<MediaItem | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const { authHeader } = useAuthContext();
  const { gmgAssetCode } = useParams<{ gmgAssetCode: string }>();

  useEffect(() => {
    if (gmgAssetCode) {
      fetchAdvertisingMediaById(gmgAssetCode);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gmgAssetCode]);

  const fetchAdvertisingMediaById = async (gmgAssetCode: string) => {
    if (!authHeader) return;
    setLoading(true);
    try {
      const mediaById = await getAdvertisingMediaById(authHeader, gmgAssetCode);
      const data = mediaById.data as MediaItem;
      setEditedItem(data);
    } catch (error) {
      console.error("Error fetching media by ID:", error);
      alert("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleImageDelete = async (imageUrl: string) => {
    if (!gmgAssetCode || !editedItem || !authHeader) return;

    setIsDeleting(true);
    try {
      await deleteFileFromS3(authHeader, imageUrl);
      setEditedItem({
        ...editedItem,
        imageUrl: "",
      });
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Failed to delete image. Please try again.");
    } finally {
      setIsDeleting(false);
    }
  };

  const updateMediaDetails = async () => {
    if (!editedItem?.service && gmgAssetCode) return;

    setLoading(true);

    try {
      const service = editedItem?.service ?? "";
      const category = editedItem?.category ?? "";
      const assetCode = gmgAssetCode || "";
      if (!authHeader) return;
      await updateAdvertisingMediaDetails(
        service,
        category,
        assetCode,
        editedItem,
        authHeader
      );
    } catch (error) {
      console.error("Failed to update media details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && authHeader) {
      try {
        const formData = new FormData();
        formData.append("image", file);

        const metadata = {
          fileName: file.name,
          uploaderName: ``,
        };

        formData.append("tags", "MEDIA IMAGE");
        formData.append("metadata", JSON.stringify(metadata));

        const res = await uploadFileWithMetadata(authHeader, formData);
        const responseData = await res.json();
        const imageUrl = responseData.fileData?.fileUrl;

        if (imageUrl && editedItem) {
          setEditedItem({
            ...editedItem,
            imageUrl: imageUrl,
          });
        } else {
          console.error("Missing imageUrl or editedItem:", { imageUrl, editedItem });
          throw new Error("Failed to get image URL from response");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("Failed to upload image. Please try again.");
      }
    }
  };

  const handleInputChange = useCallback((field: string, value: string) => {
    if (editedItem) {
      setEditedItem({ ...editedItem, [field]: value });
    }
  }, [editedItem]);

  const handleFaqChange = useCallback((value: string) => {
    setEditedItem((prev) => (prev ? { ...prev, faq: value } : null));
  }, []);

  const handleKeyInsightChange = (index: number, value: string) => {
    if (editedItem && editedItem.keyInsights) {
      const updatedInsights = [...editedItem.keyInsights];
      updatedInsights[index].value = value;
      setEditedItem({ ...editedItem, keyInsights: updatedInsights });
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleSave = () => {
    if (editedItem) {
      updateMediaDetails();
    }
  };

  return (
    <Box>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" textAlign={"center"}>
            <i>Service </i>- {editedItem?.service}
          </Typography>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" textAlign={"center"}>
            <i>Category </i>- {editedItem?.category}
          </Typography>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" textAlign={"center"}>
            <i>ID</i> - {gmgAssetCode}
          </Typography>
        </Toolbar>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="edit media tabs"
          sx={{
            backgroundColor: 'background.paper',
            '& .MuiTabs-indicator': {
              backgroundColor: 'primary.main',
              height: 3,
            },
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 'bold',
              color: 'text.secondary',
              '&.Mui-selected': {
                color: 'primary.main',
              },
            },
          }}
        >
          <Tab label="Media Details" />
          <Tab label="Advertising Options" />
        </Tabs>
      </AppBar>
      <Container>
        <DialogContent>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
              <CircularProgress />
            </Box>
          ) : editedItem ? (
            <Box>
              {currentTab === 0 && (
                <>
                  <Typography variant="h5" gutterBottom>
                    Edit Media Item
                  </Typography>
                  <Grid container spacing={2}>
                    {Object.entries(editedItem).map(([key, value]) => {
                      if (
                        key === "keyInsights" ||
                        key === "imageUrl" ||
                        key === "faq" ||
                        key === "advertisingOptions"
                      ) {
                        return null;
                      }

                      return (
                        <Grid item xs={12} sm={6} key={key}>
                          <TextField
                            label={key.charAt(0).toUpperCase() + key.slice(1)}
                            value={value || ""}
                            fullWidth
                            margin="normal"
                            onChange={(e) => handleInputChange(key, e.target.value)}
                            disabled={key === "gmgAssetCode"}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  {editedItem?.faq && (
                    <FAQEditor value={editedItem.faq} onChange={handleFaqChange} />
                  )}
                  {editedItem.keyInsights && (
                    <Box mt={4}>
                      <Typography variant="h6" gutterBottom>
                        Key Insights
                      </Typography>
                      <Grid container spacing={2} alignItems="center">
                        {editedItem.keyInsights.map((insight, index) => (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                            <TextField
                              label={insight.key}
                              fullWidth
                              margin="normal"
                              value={insight.value || ""}
                              onChange={(e) => handleKeyInsightChange(index, e.target.value)}
                              disabled={insight.key === "category"}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}
                  <Box mt={4} textAlign="center">
                    <Typography variant="h6" gutterBottom>
                      Image
                    </Typography>
                    {editedItem?.imageUrl ? (
                      <ImageContainer>
                        <Box
                          component="img"
                          src={editedItem.imageUrl}
                          alt="Media"
                          sx={{
                            maxWidth: "100%",
                            maxHeight: 300,
                            objectFit: "contain",
                          }}
                        />
                        <ImageOverlay className="overlay">
                          <Button
                            variant="contained"
                            color="error"
                            startIcon={<DeleteIcon />}
                            onClick={() => handleImageDelete(editedItem.imageUrl)}
                            disabled={isDeleting}
                            sx={{ backgroundColor: "rgba(255, 0, 0, 0.7)" }}
                          >
                            {isDeleting ? "Deleting..." : "Delete Image"}
                          </Button>
                        </ImageOverlay>
                      </ImageContainer>
                    ) : (
                      <Button component="label" variant="outlined">
                        <ImageUploadBox>
                          <Box sx={{ textAlign: "center" }}>
                            <PhotoCameraIcon
                              sx={{ fontSize: 48, color: "text.secondary", mb: 1 }}
                            />
                            <Typography variant="body2" color="text.secondary">
                              Click to upload image
                            </Typography>
                          </Box>
                        </ImageUploadBox>
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleImageUpload}
                          accept="image/*"
                        />
                      </Button>
                    )}
                  </Box>
                  <Box mt={4} display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ ml: 2 }}
                      onClick={handleSave}
                    >
                      Save Changes
                    </Button>
                  </Box>
                </>
              )}

              {currentTab === 1 && (
                <>
                  <Typography variant="h5" gutterBottom>
                    Advertising Options
                  </Typography>
                  {editedItem.advertisingOptions && (
                    <AdvertisingOptionsList gmgAssetCode={gmgAssetCode} advertisingOptions={editedItem.advertisingOptions} />
                  )}
                </>
              )}
            </Box>
          ) : (
            <Typography>Error: Media data not available.</Typography>
          )}
        </DialogContent>
      </Container>
    </Box>
  );
};

export default EditMediaDialog;
// src/hooks/useImageUpload.ts

import { useState, useCallback } from "react";
import { uploadFileWithMetadata } from "../../../clients/uploadFileWithMetadata";

interface ImageUploadState {
  isUploading: boolean;
  selectedFileName: string | null;
  imageUrl: string | null;
}

interface ImageUploadActions {
  handleImageUpload: (imageFile: File) => Promise<void>;
  reset: () => void;
}


export const useImageUpload = (): [ImageUploadState, ImageUploadActions] => {
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleImageUpload = useCallback(async (imageFile: File) => {
    setIsUploading(true);
    setSelectedFileName(imageFile.name);

    try {
      const imageData = new FormData();
      imageData.append(
        "additionalFileData",
        JSON.stringify("AdvertisingMedia")
      );
      imageData.append("file", imageFile);

      const response = await uploadFileWithMetadata(imageData);

      if (response.ok) {
        const responseData = await response.json();
        const imageFile = responseData.uploadedFileData.fileUrl as string;
        setImageUrl(imageFile);
        console.log("Image uploaded successfully!", responseData);
        setIsUploading(false)
      } else {
        console.error("Failed to upload image");
      }

    } catch (error) {
      console.error("Image upload failed:", error);
    } finally {
      setIsUploading(false);
    }
  }, []);

  const reset = useCallback(() => {
    setIsUploading(false);
    setSelectedFileName("");
    setImageUrl(null);
  }, []);

  const state: ImageUploadState = {
    isUploading,
    selectedFileName,
    imageUrl,
  };

  const actions: ImageUploadActions = {
    handleImageUpload,
    reset,
  };

  return [state, actions]; // Return as tuple
};
